import React, { Component } from 'react';
import { withRouter } from 'react-router';
import validator from 'validator';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../msal/MsalConfig';
import { getUserByID } from '../graph/GraphService';
import { loginRequest } from '../msal/MsalConfig';
import { PrimaryButton } from '@fluentui/react';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider";
import { Modal, Button } from "react-bootstrap";
import { checkEmail } from "./ImportEmployees"
const ValidationMessage = ({ error }) => {
    return error !== "" ? <p className="text-danger" style={{ fontSize: "13px" }}> {error} </p> : null;
};

class AddEmployeeModal extends Component {
    static displayName = AddEmployeeModal.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0, newUser: null, navigate: false, newUserStatus: false,
            carriersList: [
                { key: 'AT&T Wireless', value: 'ATT Wireless' },
                { key: 'Sprint', value: 'Sprint' },
                { key: 'T-Mobile', value: 'T-Mobile' },
                { key: 'Verizon', value: 'Verizon' },
                { key: 'Vergin Mobile', value: 'Vergin Mobile' },
                { key: 'Boost Mobile', value: 'Boost Mobile' },
                { key: 'Altel Wireless', value: 'Altel Wireless' },
                { key: 'Qwest Mobile', value: 'Qwest Mobile' }],
            RoleID: props.roleID,
            employeeID: props.employeeID,
            hasInvalidEmail: false,
            validationErrors: {
                firstname: '',
                email: '',
                totalvouchers: '',
                phonenumber: ''
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleEmpSubmit = this.handleEmpSubmit.bind(this);
        this.onEmailInput = this.onEmailInput.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.phoneMask = this.phoneMask.bind(this);
        this.setValidationError = this.setValidationError.bind(this);
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            empData: {
                ...prevState.empData,
                [e.target.name]: e.target.value
            }
        }))

        if (e.target.name === "IsAdmin") {
            this.state.newUser.IsAdmin = e.target.checked
        }
    }

    onChangeNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(prevState => ({
                empData: {
                    ...prevState.empData,
                    [e.target.name]: e.target.value
                }
            }))
        } else {
            e.target.value = null;
        }
    }

    componentDidMount() {
        console.log(this.props);
        console.log(this.props.RequestedEmployee);
        if (this.props.RequestedEmployee) {
            this.setState({
                newUser: this.props.RequestedEmployee
            })
        }

        if (this.state.employeeID != 0) {
            this.fetchEmployeeByID();
        } else {
            this.state.newUserStatus = true;
        }
    }

   validatePhone(phone) {
        var isvalid = true;
        if (this.state.empData.TxtMessages == "true") {
            if (this.state.empData.PhoneNumber == "" || this.state.empData.PhoneNumber == undefined || this.state.empData.PhoneNumber.length < 13) {
                isvalid = false;
            }
        } else if (this.state.empData.TxtMessages == "false" && this.state.empData.PhoneNumber != "" && this.state.empData.PhoneNumber.length < 13) {
                isvalid = false;
        }
        return isvalid;
    }

    fetchEmployeeByID() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Employee/fetchEmployeebyId?employeeID=' + this.state.employeeID, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    this.setState({ newUser: result, empData: result });
                    console.log(result);
                });
        });
    }

    setValidationError(name , message) {
        this.setState((prevState) => ({
            validationErrors: {
                ...prevState.validationErrors,
                [name]: message,
            },
        }));
    }

   
    handleEmpSubmit(event) {
        if (this.state.empData != undefined) {
            this.state.empData.Id = document.getElementById("hdnempid").value;
            /* this.state.empData.UserName = document.getElementById("username").value;*/
            this.state.empData.FirstName = document.getElementById("firstname").tagName == 'LABEL' ? this.state.newUser.FirstName : document.getElementById("firstname").value;
            this.state.empData.LastName = document.getElementById("lastname").tagName == 'LABEL' ? this.state.newUser.LastName :  document.getElementById("lastname").value;
            this.state.empData.Email = document.getElementById("email").tagName == 'LABEL' ? this.state.newUser.Email :  document.getElementById("email").value;
            this.state.empData.TotalVouchers = document.getElementById("totalvouchers") == undefined ? this.state.newUser.TotalVouchers : document.getElementById("totalvouchers").value;
            this.state.empData.TxtMessages =  document.querySelector('input[id="txtmessages"]:checked').value;
            this.state.empData.PhoneNumber = document.getElementById("phonenumber").value;
            /*this.state.empData.Carrier = document.getElementById("carrier").value;*/
            this.state.empData.Status = document.querySelector('input[id="status"]:checked') == undefined ? this.state.newUser.Status.toString()  :  document.querySelector('input[id="status"]:checked').value;
            this.state.empData.IsAdmin = document.getElementById("permissions") == undefined ? "false" : document.getElementById("permissions").checked.toString();
            this.state.empData.ExternalIdentifier = this.state.newUser.ExternalIdentifier;
            if (this.handleValidation(event)) {
                let data = JSON.stringify(this.state.empData);
                msalAuth.acquireTokenSilent({
                    ...APIAuth,
                    account: msalAuth.getAllAccounts()[0] //get tocken to access the api
                }).then(async (presponse) => {
                   await msalAuth.acquireTokenSilent({
                        ...loginRequest,
                        account: msalAuth.getAllAccounts()[0] //get tocken to access the api
                    }).then(async (response) => {
                        await checkEmail(response, this.state.empData.Email).then((response) => {
                            if (response == true  || (this.state.empData.Status == "false" && this.state.empData.Id != "0" )) {
                                fetch('Employee/AddEmployee', prepareHeader(presponse, "POST", data))
                                    .then((response) => response.json())
                                    .then(data => {
                                        var result = data;
                                        console.log(result);
                                        if (result.toString().indexOf("Added") > -1 || result.toString().indexOf("Updated") > -1) {
                                            global.toastMeForSuccess(result)
                                            if (this.props.Page != "profile") {
                                                this.props.history.push('/employees')
                                            }
                                            this.props.hideModal();
                                            this.props.FetchEmployees();
                                        }
                                        else if (result === "User Already Exists") {
                                            global.toastMeForWarning(result)
                                        }
                                    });
                            }
                            else {
                                global.toastMeForWarning("User not found in AD");
                            }
                        }).catch(err => {
                            global.toastMeForWarning("User not found in AD");
                        });
                    });
                });
            }
            event.preventDefault();
        } else {
            global.toastMeForWarning("Please fill the form")
        }
    }

    handleValidation(event) {
        /* document.getElementById("username").classList.remove("input-error");*/
        var validation = [];
        document.getElementById("firstname").classList.remove("input-error");
        document.getElementById("lastname").classList.remove("input-error");
        document.getElementById("email").classList.remove("input-error");
        document.getElementById("totalvouchers")?.classList.remove("input-error");
        document.getElementById("txtMsg").classList.remove("input-error");
        document.getElementById("phonenumber").classList.remove("input-error");
        /*document.getElementById("carrier").classList.remove("input-error");*/
        document.getElementById("status")?.classList.remove("input-error");
        if (document.getElementById("securitystamp") != null || document.getElementById("securitystamp") != undefined)
            document.getElementById("securitystamp").classList.remove("input-error");
        if (this.state.empData != undefined) {
            //if (this.state.empData.UserName == "" || this.state.empData.UserName == undefined) {
            //    document.getElementById("username").classList.add("input-error");
            //}
            if (this.state.empData.FirstName == "" || this.state.empData.FirstName == undefined) {
                document.getElementById("firstname").classList.add("input-error");
                this.setValidationError("firstname","Please enter first name")
            }
            //if (this.state.empData.LastName == "" || this.state.empData.LastName == undefined) {
            //    document.getElementById("lastname").classList.add("input-error");
            //}
            if (this.state.empData.Email == "" || this.state.empData.Email == undefined || !validator.isEmail(this.state.empData.Email)) {
                document.getElementById("email").classList.add("input-error");
                this.setValidationError("email", "Please enter email")
            }
            if (this.state.empData.TotalVouchers == "" || this.state.empData.TotalVouchers == undefined) {
                document.getElementById("totalvouchers").classList.add("input-error");
                this.setValidationError("totalvouchers", "Please enter total vouchers")
            }
            if (this.state.empData.TxtMessages == "" || this.state.empData.TxtMessages == undefined) {
                document.getElementById("txtMsg").classList.add("input-error");
            }
            if (!this.validatePhone(this.state.empData.PhoneNumber)) {
                document.getElementById("phonenumber").classList.add("input-error");
                this.setValidationError("phonenumber", "Please enter valid phone number")
            }
            //if (this.state.empData.Carrier == "" || this.state.empData.Carrier == undefined) {
            //    document.getElementById("carrier").classList.add("input-error");
            //}
            if (this.state.empData.Status == "" || this.state.empData.Status == undefined) {
                document.getElementById("status")?.classList.add("input-error");
            }
            if (this.state.empData.FirstName != "" &&
                //this.state.empData.LastName != "" &&
                this.state.empData.Email != "" && validator.isEmail(this.state.empData.Email) &&
                this.state.empData.TotalVouchers != "" &&
                this.state.empData.TxtMessages != "" &&
                (this.validatePhone(this.state.empData.PhoneNumber)) &&
                /*this.state.empData.Carrier != "" &&*/
                this.state.empData.Status != "") {
                return true;
            } else {
                return false;
            }
        } else {
            global.toastMeForWarning("Please fill the form")
        }
    }


    //PS:SC To validate email and autopopulate the user info.
    onEmailInput(e) {
        this.state.hasInvalidEmail = false;
        if (e.target.value !== "" && this.state.employeeID == 0) {
            const msalInstance = new PublicClientApplication(msalConfig);
            msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: msalInstance.getAllAccounts()[0]
            }).then((response) => {
                // Acquire token silent success
                getUserByID(response, e.target.value).then(data => {
                    if (data !== null) {
                        this.setState({
                            newUser: {
                                UserName: data.givenName + "." + data.surname,
                                FirstName: data.givenName,
                                LastName: data.surname,
                                Email: data.userPrincipalName,
                                CellPhone: data.mobilePhone,
                                ExternalIdentifier: data.id
                            }
                        });
                    }
                }).catch((error) => {
                    e.target.value = "";
                    this.state.hasInvalidEmail = true;
                    this.setState({
                        newUser: {
                            UserName: null,
                            FirstName: null,
                            LastName: null,
                            Email: null,
                            CellPhone: null,
                            ExternalIdentifier: null
                        }
                    });
                    // If .catch is called, this means your app was returning from a redirect operation
                    // and an error occurred.
                });
            });
        }
    }

   phoneMask(e) {
        var phoneValue = this.formatPhoneNumber(e.target.value);
        e.target.value = phoneValue;
    }

    formatPhoneNumber(value) {
          // if input value is falsy eg if the user deletes the input, then just return
          if (!value) return value;

          // clean the input for any non-digit values.
          const phoneNumber = value.replace(/[^\d]/g, '');

          // phoneNumberLength is used to know when to apply our formatting for the phone number
          const phoneNumberLength = phoneNumber.length;

          // we need to return the value with no formatting if its less then four digits
          // this is to avoid weird behavior that occurs if you  format the area code to early

          if (phoneNumberLength < 4) return phoneNumber;

          // if phoneNumberLength is greater than 4 and less the 7 we start to return
          // the formatted number
          if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
          }

          // finally, if the phoneNumberLength is greater then seven, we add the last
          // bit of formatting and return it.
          return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(
            3,
            6
          )}-${phoneNumber.slice(6, 10)}`;
        }

    render() {
        const { firstname:firstErr,email:emailErr,totalvouchers:tvoucherErr,phonenumber:phoneErr } = this.state.validationErrors
        return (
            <div>
                <div className="row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"><h5>{this.props.Page == "profile" ? "Profile" : this.state.employeeID == 0 ? "Add Employee" : "Edit Employee"}</h5></div>
                    <input type="hidden" id="hdnempid" value={this.state.employeeID} />
                </div>
                <div className="row mld-alter-row left mld-row  mt-4">
                    <div className="col-md-1"></div>
                    <div className="col-md-5 left">Email</div>
                    <div className="col-md-3"> {this.props.Page == "profile" ? <label className="mld-text-gold" id="email"> {this.state.newUser?.Email} </label>   : <input type="text" id="email" readOnly={this.state.employeeID != 0} name="Email" defaultValue={this.state.newUser?.Email} onBlur={this.onEmailInput} className="mld-textBox-noBorder ms-TextField-field" onChange={this.handleChange} />} </div>
                    <div className="col-md-3">
                        {this.state.hasInvalidEmail ?
                            <span className="text-danger" style={{ fontSize: "13px" }}> Email not found in AD </span>
                            : ""
                        }
                    </div>
                </div>
                {/*<div className="row  left mld-row">*/}
                {/*    <div className="col-sm-1"></div>*/}
                {/*    <div className="col-md-5 left">Username</div>*/}
                {/*    <div className="col-md-4"> <input type="text" id="username" readOnly={this.state.employeeID != 0} name="UserName" defaultValue={this.state.newUser?.UserName} className="mld-textBox-noBorder ms-TextField-field" onChange={this.handleChange} /> </div>*/}
                {/*    <div className="col-md-3"> </div>*/}
                {/*</div>*/}
                {this.props.Page != "profile" ?
                    <React.Fragment>
                        <div className="row left mld-row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5 left">First Name</div>
                            <div className="col-md-3"> {this.props.Page == "profile" ? <label id="firstname" value={this.state.newUser?.FirstName}> {this.state.newUser?.FirstName} </label> : <input type="text" id="firstname" readOnly={this.state.employeeID != 0} name="FirstName" defaultValue={this.state.newUser?.FirstName} className="mld-textBox-noBorder ms-TextField-field" onChange={this.handleChange} />} </div>
                            <div className="col-md-3">
                                <ValidationMessage error={firstErr} />
                            </div>
                        </div>
                        <div className="row mld-alter-row left mld-row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5 left">Last Name</div>
                            <div className="col-md-3"> {this.props.Page == "profile" ? <label id="lastname"> {this.state.newUser?.LastName} </label> : <input type="text" id="lastname" readOnly={this.state.employeeID != 0} name="LastName" defaultValue={this.state.newUser?.LastName} className="mld-textBox-noBorder ms-TextField-field" onChange={this.handleChange} />} </div>
                            <div className="col-md-3"> </div>
                        </div>
                    </React.Fragment>
                    :
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Name</div>
                        <div className="col-md-3">  <label id="firstname" className="mld-text-gold" value={this.state.newUser?.FirstName}> {this.state.newUser?.FirstName} </label> <label id="lastname" className="mld-text-gold"> {this.state.newUser?.LastName} </label>  </div>
                        <div className="col-md-3">
                            <ValidationMessage error={firstErr} />
                        </div>
                    </div>}
                {this.props.Page != "profile" ?
                    <div className="row  left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Total Vouchers</div>
                        <div className="col-md-3"> {this.props.Page == "profile" ? <label className="mld-text-gold" id="totalvouchers"> {this.state.newUser?.TotalVouchers} </label> : <input type="text" style={{ width: "100%" }} min="0" maxLength="4" name="TotalVouchers" defaultValue={this.state.newUser?.TotalVouchers} id="totalvouchers" className="mld-textBox-noBorder ms-TextField-field" onChange={this.onChangeNumber} />} </div>
                        <div className="col-md-3">
                            <ValidationMessage error={tvoucherErr} />
                        </div>
                    </div> : ""}
                <div className="row mld-alter-row  left mld-row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5 left">Text Messages</div>
                    <div className="col-md-4">
                        <div className="row" id="txtMsg">
                            <div className="col-md-6 mld-text">
                                <input type="radio" id="txtmessages" name="msg" name="TxtMessages" defaultChecked={this.state.newUser?.TxtMessages} value={true} className="form-check-input mld-radio" onChange={this.handleChange} /> <label>On</label>
                            </div>
                            <div className="col-md-6 mld-text">
                                <input type="radio" id="txtmessages" name="msg" name="TxtMessages" defaultChecked={!this.state.newUser?.TxtMessages} value={false} className="form-check-input mld-radio" onChange={this.handleChange} /> <label>Off</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"> </div>
                </div>
                <div className="row  left mld-row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5 left">Cell Phone</div>
                    <div className="col-md-3"> <input type="text" id="phonenumber" name="PhoneNumber" defaultValue={this.state.newUser?.PhoneNumber} className="mld-textBox-noBorder ms-TextField-field" maxLength="13" minLength="13" onChange={this.phoneMask} /> </div>
                    <div className="col-md-3">
                        <ValidationMessage error={phoneErr} />
                    </div>
                </div>
                {/*<div className="row mld-alter-row left mld-row">*/}
                {/*    <div className="col-md-1"></div>*/}
                {/*    <div className="col-md-5">Carrier</div>*/}
                {/*    <div className="col-md-3">*/}
                {/*        <select className="mld-ddl" name='Carrier' id="carrier" onChange={this.handleChange} value={this.state.voucherData?.Carrier} >*/}
                {/*            {this.state.carriersList.map((m, i) => {*/}
                {/*                return <option value={m.value} selected={this.state.newUser?.Carrier == m.value ? true : false}> {m.value} </option>*/}
                {/*            })}*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-3"> </div>*/}
                {/*</div>*/}
                {this.props.Page != "profile" ?
                    <React.Fragment>
                        <div className="row   left mld-row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">Status</div>
                            <div className="col-md-4">
                                <div className="row" id="status">
                                    <div className="col-md-6  mld-text">
                                        <input type="radio" id="status" name="Status" disabled={this.state.newUser?.EmployeesInRoles == undefined ? false : this.state.newUser?.EmployeesInRoles.some(e => e.RoleId == 1)} className="form-check-input mld-radio" defaultChecked={this.state.newUserStatus == true ? true : this.state.newUser?.Status} value={true} onChange={this.handleChange} /> <label>Active</label>
                                    </div>
                                    <div className="col-md-6  mld-text">
                                        <input type="radio" id="status" name="Status" disabled={this.state.newUser?.EmployeesInRoles == undefined ? false : this.state.newUser?.EmployeesInRoles.some(e => e.RoleId == 1)} className="form-check-input mld-radio" defaultChecked={!this.state.newUser?.Status} value={false} onChange={this.handleChange} /> <label>Inactive</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"> </div>
                        </div>
                        {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                            <div className="row mld-alter-row left mld-row">
                                <div className="col-md-1"></div>
                                <div className="col-md-5">Is Admin</div>
                                <div className="col-md-5" id="securitystamp">
                                    <div className="row">
                                        <div className="col-md-6 mld-text">
                                            <input type="checkbox" id="permissions" disabled={this.state.newUser?.EmployeesInRoles == undefined ? false : this.state.newUser?.EmployeesInRoles.some(e => e.RoleId == 1)} name="IsAdmin" className="form-check-input mld-checK" checked={this.state.newUser?.IsAdmin} onChange={this.handleChange} /> {/*<label>Fulfillment</label>*/}
                                        </div>
                                        {/*<div className="col-md-6 mld-text">*/}
                                        {/*    <input type="checkbox" id="permissions" name="SecurityStamp" className="form-check-input mld-checK" onChange={this.handleChange} /> <label>Schedule</label>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-md-6 mld-text">*/}
                                    {/*        <input type="checkbox" id="permissions" name="SecurityStamp" className="form-check-input mld-checK" onChange={this.handleChange} /> <label>Employee</label>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-md-6 mld-text">*/}
                                    {/*        <input type="checkbox" id="permissions" name="SecurityStamp" className="form-check-input mld-checK" onChange={this.handleChange} /> <label>Maintenance</label>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            : ""}
                    </React.Fragment>
                    : ""}
                <div className="row center mt-4">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"><PrimaryButton positive onClick={this.handleEmpSubmit} className="wld-primary-button">Save</PrimaryButton></div>
                    <div className="col-md-3"><PrimaryButton onClick={this.props.hideModal} className="wld-secondary-button">Cancel</PrimaryButton></div>
                    <div className="col-md-3"> </div>
                </div>
            </div>
        );
    }
}

export default AddEmployeeModal = withRouter(AddEmployeeModal);