import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';

export class Schedule extends Component {
    static displayName = Schedule.name;

    constructor(props) {
        super(props);
        this.state = {
            isGModalOpen: false,
            isVModalOpen: false,
            gamesData: [],
            selectedGame: 0,
            sortType: 'ascending',
            key: null,
            sortIcon: 'SortUp'
        };

        this.showGModal = this.showGModal.bind(this);
        this.hideGModal = this.hideGModal.bind(this);

        this.showVModal = this.showVModal.bind(this);
        this.hideVModal = this.hideVModal.bind(this);
    }

    componentDidMount() {
        this.FetchGames();
    }

    //PS:SC To fetch games.
    FetchGames() {
        fetch('Game/fetchGames')
            .then((response) => response.json())
            .then(data => {
                this.setState({ gamesData: JSON.parse(data) });
            });
    }

    //For Edit Games Voucher
    showGModal(event) {
        console.log(event);
        this.setState({ isGModalOpen: true });
    }
    hideGModal() {
        this.setState({ isGModalOpen: false });
    }

    //For RequestVoucher Modal
    showVModal(event) {
        console.log(event);
        this.setState({ isVModalOpen: true });
    }
    hideVModal() {
        this.setState({ isVModalOpen: false });
    }

    requestSort = (key) => {
        this.setState({ key: key });
        if (this.state.sortType === null || this.state.sortType === 'descending')
            this.setState({ sortType: 'ascending', sortIcon: 'SortUp' });
        if (this.state.sortType === 'ascending') {
            this.setState({ sortType: 'descending', sortIcon: 'SortDown' });
        }
    };


    render() {        

        this.state.gamesData.sort((a, b) => {
            if (a[this.state.key] < b[this.state.key]) {
                return this.state.sortType === 'ascending' ? -1 : 1;
            }
            if (a[this.state.key] > b[this.state.key]) {
                return this.state.sortType === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                <PrimaryButton className="wld-primary-button" onClick={this.showGModal}>Add Game</PrimaryButton>
                <table className="mld-table table">
                    <thead>
                        <tr>
                            <th scope="col">Opponent</th>
                            <th scope="col">Game Time</th>
                            <th scope="col">Status</th>
                            <th scope="col">Max Voucher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.gamesData.map((item, i) => (
                            <tr key={item.ID}>
                                <td>{item.GameTitle}</td>
                                <td>{item.GameTime}</td>
                                <td className={item.Status == 'Good' ? "text-success" : "text-danger"} >{item.Status}</td>
                                <td>{item.MaxVouchers}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div>
        );
    }
}