import React, { Component, useState } from 'react';
import { PrimaryButton, IconButton } from '@fluentui/react';
import { Modal, Button } from "react-bootstrap";
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import ImportEmployees from './ImportEmployees';
import { loginRequest } from '../msal/MsalConfig';
import AddEmployeeModal from './AddEmployeeModal';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../msal/MsalConfig';
import { getUserByID } from '../graph/GraphService';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { Buffer } from 'buffer';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider";
import { trackPromise } from 'react-promise-tracker';

export const EmployeeUpdateModel = {
            ID: 0,
            UserName: null,
            FirstName: null,
            LastName: null,
            Email: null,
            SecurityStamp: null,
            TotalVouchers: null,
            TxtMessages: null,
            PhoneNumber: null,
            PhoneNumberConfirmed: null,
            Carrier: null,
            Status: null,
            ExternalIdentifier: null
}

export class Employee extends Component {
    static displayName = Employee.name;

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            isModalOpen: false,
            isIModalOpen: false,
            sortType: 'ascending',
            key: null,
            sortIcon: 'SortUp',
            employeeData: [],
            employeesData: [],
            empData: {
                ID: 0,
                UserName: '',
                FirstName: '',
                LastName: '',
                Email: '',
                SecurityStamp: '',
                TotalVouchers: '',
                TxtMessages: false,
                PhoneNumber: '',
                PhoneNumberConfirmed: false,
                //Carrier: '',
                Status: false,
                ExternalIdentifier: ''
            },
            newUserID: this.props.match == undefined ? 0 : this.props.match.params.userID,
            isApproved: this.props.match == undefined ? 0 : this.props.match.params.isApproved,
            requestID: this.props.match == undefined ? 0 : this.props.match.params.requestID,
            RquestedEmployee: {
                UserName: "",
                FirstName: "",
                LastName: "",
                Email: "",
                CellPhone: "",
                ExternalIdentifier:""
            },
            empIDForEdit:0,
            RoleID : props.auth.RoleID
        };
        
        this.FetchEmployees = this.FetchEmployees.bind(this);
        this.GetAllEmployees = this.GetAllEmployees.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showIModal = this.showIModal.bind(this);
        this.hideIModal = this.hideIModal.bind(this);
        initializeIcons();
        console.log(this.state.newUserID);
        
    }


    showModal(id) {
        console.log(id);
        this.setState({ isModalOpen: true });
        this.state.empIDForEdit = id;
    }
    hideModal() {
        this.setState({ isModalOpen: false });
    }

    showIModal() {
        this.setState({ isIModalOpen: true });
    }
    hideIModal() {
        this.setState({ isIModalOpen: false });
    }

    componentDidMount() {

        if (this.state.newUserID) {
            var data = {
                newUserID: this.state.newUserID,
                isApproved: this.state.isApproved,
                requestID: this.state.requestID
            }
            this.decryptUrlParams(JSON.stringify(data));
        }

        if (this.state.RoleID.some(s=> Roles.userRoles.includes(s))) {
            global.toastMeForError("Permission denied")
            this.props.history.push('/');
        }

        this.FetchEmployees();
        this.GetAllEmployees();

      
        
    }
    //PS:SC To Decrypt the url params for new user.
    decryptUrlParams(base64String, stateKey) {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Account/DecryptKey?key=' + base64String, prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
                
                var result = JSON.parse(data);
                console.log(result);
                this.setState({
                    newUserID: result.newUserID,
                    isApproved: result.isApproved,
                    requestID: result.requestID
                })
                if (this.state.newUserID && this.state.isApproved == 1) {
                    this.AddNewEmployee();

                }
                else if (this.state.newUserID && this.state.isApproved == 0) {
                    global.toastMeForSuccess("Request declined successfully")
                }
            });
        });
      
    }

    //PS:SC To fetch Employees.
    FetchEmployees() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
            fetch('Employee/fetchEmployee', prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
                this.setState({ employeeData: JSON.parse(data) });
                console.log(this.state.employeeData)
            }));
        });
    }

    GetAllEmployees() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Employee/GetAllEmployees', prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
                var result = JSON.parse(data);
                var result = JSON.parse(data)
                result.forEach(elm => delete elm.ExternalIdentifier)
                result.forEach(elm => delete elm.Id)
                result.forEach(elm => delete elm.UserName)
                result.forEach(elm => delete elm.PhoneNumberConfirmed)
                this.setState({ employeesData: result });
                console.log(this.state.employeesData)
            });
        });
    }


    AddNewEmployee() {
        this.hideModal();
        const msalInstance = new PublicClientApplication(msalConfig);
        const userid = this.state.newUserID;
        var useroptions = null;
        msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: msalInstance.getAllAccounts()[0]
        }).then((response) => {
            // Acquire token silent success
            getUserByID(response,this.state.newUserID).then(data => {
                this.setState({
                    RquestedEmployee:
                    {
                        UserName: data.givenName + "." + data.surname,
                        FirstName: data.givenName,
                        LastName: data.surname,
                        Email: data.userPrincipalName,
                        CellPhone: data.mobilePhone,
                        ExternalIdentifier: data.id
                    }
                });
                this.showModal();
                this.GetAllEmployees();
            })
        });
    }


    requestSort = (key) => {
        this.setState({ key: key });
        if (this.state.sortType === null || this.state.sortType === 'descending')
            this.setState({ sortType: 'ascending', sortIcon: 'SortUp' });
        if (this.state.sortType === 'ascending') {
            this.setState({ sortType: 'descending', sortIcon: 'SortDown' });
        }
    };

    render() {

        //const { userId, isAutorize } = this.props.match.params;
        this.state.employeeData.sort((a, b) => {
            var aTemp = a[this.state.key]; var bTemp = b[this.state.key];
            if (String(aTemp).trim().toLowerCase() < String(bTemp).trim().toLowerCase()) {
                return this.state.sortType === 'ascending' ? -1 : 1;
            }
            if (String(aTemp).trim().toLowerCase() > String(bTemp).trim().toLowerCase()) {
                return this.state.sortType === 'ascending' ? 1 : -1;
            }
            return 0;
        });


        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button float-start custombtn" onClick={() => this.showModal(0)}>Add Employee</PrimaryButton> : ""}
                {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ?
                    <PrimaryButton className="wld-primary-button custombtn" style={{ float: "right", fontWeight: "600" }}><CSVLink
                        filename={"Employees_" + moment(new Date()).format("YYYYMMDDhhmmss") +".csv"}
                        data={this.state.employeesData}
                    >
                        Export Employees
                    </CSVLink> </PrimaryButton> : ""
                }
                {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button custombtn" style={{ float: "right" }} onClick={this.showIModal}>Import Employees</PrimaryButton> : ""}
                <div className="fCard Card tableborder">
                <table className="mld-table table">
                    <thead>
                        <tr>
                           {/* <th className="left"><label className="ms-4">Username</label></th>*/}
                            <th className="left" scope="col" onClick={() => this.requestSort('FirstName')}>
                                First Name
                                {this.state.key == "FirstName" ? 
                                    <IconButton
                                        iconProps={{ iconName: this.state.sortIcon }}
                                        styles={{
                                            icon: {
                                                color: '#DFC9A2', fontSize: 11 },
                                            root: {
                                                height: 0,
                                                selectors: {
                                                    ':hover .ms-Button-icon': {
                                                        color: '#DFC9A2'
                                                    },
                                                    ':active .ms-Button-icon': {
                                                        color: '#DFC9A2'
                                                    }
                                                }
                                            }
                                        }} />
                                    : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                }
                            </th>
                            <th className="left" scope="col" onClick={() => this.requestSort('LastName')}>
                                Last Name
                                {this.state.key == "LastName" ? 
                                <IconButton
                                    iconProps={{ iconName: this.state.sortIcon }}
                                    styles={{
                                        icon: { color: '#DFC9A2', fontSize: 11 },
                                        root: {
                                            height: 0,
                                            selectors: {
                                                ':hover .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                },
                                                ':active .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                }
                                            }
                                        }
                                        }} />
                                    : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                }
                            </th>
                            <th scope="col" onClick={() => this.requestSort('Status')}>
                                Active
                                {this.state.key == "Status" ?
                                <IconButton
                                    iconProps={{ iconName: this.state.sortIcon }}
                                    styles={{
                                        icon: {
                                            color: '#DFC9A2', fontSize: 11
                                        },
                                        root: {
                                            height: 0,
                                            selectors: {
                                                ':hover .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                },
                                                ':active .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                }
                                            }
                                        }
                                    }} />
                                : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                            }
                            </th>
                            <th scope="col" onClick={() => this.requestSort('IsAdmin')}>
                            Is Admin
                            {this.state.key == "IsAdmin" ?
                                <IconButton
                                    iconProps={{ iconName: this.state.sortIcon }}
                                    styles={{
                                        icon: {
                                            color: '#DFC9A2', fontSize: 11
                                        },
                                        root: {
                                            height: 0,
                                            selectors: {
                                                ':hover .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                },
                                                ':active .ms-Button-icon': {
                                                    color: '#DFC9A2'
                                                }
                                            }
                                        }
                                    }} />
                                : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                            }
                            </th>
                            <th scope="col">Total Voucher</th>
                            <th scope="col">Voucher Requested</th>
                            <th scope="col">Voucher Approved</th>
                            <th scope="col">Voucher Pending</th>
                            <th scope="col">Voucher Remaining</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.employeeData.map((item, i) => (
                            <tr key={item.key}>
                             {/*   <td className="left"><label className="ms-4">{item.UserName}</label></td>*/}
                                <td className="left">{item.FirstName}</td>
                                <td className="left">{item.LastName}</td>
                                <td>{item.Status == true ? "Yes" : "No"}</td>
                                <td>{item.IsAdmin == true ? "Yes" : "No"}</td>
                                <td>{item.TotalVouchers}</td>
                                <td>{item.RequestCount}</td>
                                <td>{item.ApprovedCount}</td>
                                <td>{item.PendingCount}</td>
                                <td>{item.RemainingCount}</td>
                                <td>{this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button" text="Edit" onClick={() => this.showModal(item.ID)} /> : ""}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                <Modal className="wld-Modal-md" size="lg" show={this.state.isModalOpen} onHide={this.hideModal}>
                    <Modal.Body className="ModalContentBox">
                        <AddEmployeeModal employeeID={this.state.empIDForEdit} hideModal={this.hideModal} roleID={this.state.RoleID} RequestedEmployee={this.state.RquestedEmployee} FetchEmployees={this.FetchEmployees} GetAllEmployees={this.GetAllEmployees} />
                    </Modal.Body>
                </Modal>

                {this.state.isIModalOpen ? (
                    <Modal id="hideIModal" className="wld-Modal-md" size="lg" show={this.state.isIModalOpen} onHide={this.hideIModal}>
                        <Modal.Body className="ModalContentBox">
                            <ImportEmployees allEmployeesEmails={this.state.employeeData.map(m=> {return m.Email.trim().toLowerCase()})} employeeID={this.state.employeeID} hideIModal={this.hideIModal} roleID={this.state.RoleID} FetchEmployees={this.FetchEmployees} GetAllEmployees={this.GetAllEmployees} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.empData)
                )}
            </div>
        );
    }
}