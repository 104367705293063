import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { VoucherRequestHistory } from './VoucherRequestHistory';
import './../content/Notification.css'
import { Redirect } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CsvDownload from "react-json-to-csv";
import moment from 'moment';
import { msalConfig } from '../msal/MsalConfig';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider";
import { trackPromise } from 'react-promise-tracker';


export class Home extends Component {
    static displayName = Home.name;
  
    constructor(props) {
        super(props);
        this.state = { VoucherData: [], employeeID: props.auth.UserID, RoleID: props.auth.RoleID, seasonlist: [], selectedSeason: 0, voucherHistoryForExport: null };
        this.getVoucherRequested = this.getVoucherRequested.bind(this);
    }


    componentDidMount() {
        this.getVoucherRequested();
        this.fetchSeason();
        this.fetchVoucherhistory();
    }

    //Get voucher request of employee
    getVoucherRequested() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
           trackPromise(
            fetch('Home/getRequestedVouchers?employeeID=' + this.state.employeeID, prepareHeader(response,"GET"))
                .then((response) => response.json())
                .then(data => {
                    console.log(JSON.parse(data));
                    this.setState({ VoucherData: JSON.parse(data) });
                }));
        });
    }

    // To fetch season.
    fetchSeason() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            
            fetch('Season/FetchSeason', prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    console.log(JSON.parse(data));
                    var result = JSON.parse(data);
                    var seasons = result.map(value => ({
                        id: value.Id,
                        name: value.SeasonName,
                        isActive: value.IsActive
                    }))
                    var seasons = seasons.filter(f => f.isActive == 1)
                    console.log(seasons);
                    seasons.unshift({
                        id: 0,
                        name: "All",
                        isActive: 0
                    })

                    this.setState({ seasonlist: seasons, selectedValue: seasons });
                });
        });
    }

    renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
    }

    fetchVoucherhistory() {
        var SpecifyFormat = str => str.charAt(0).toUpperCase() + str.slice(1); // To change the header in specific format
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Home/getVoucherHistory?seasonID=' + this.state.selectedSeason, prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
              var result =  data.map(obj => {
                   var output = Object.fromEntries(Object.entries(obj).map(([key, value]) => [SpecifyFormat(key), value]));
                    return output;
                })
               
                this.setState({ voucherHistoryForExport: result });
            });
        });
    }

    handleSeasonChange = (e) => {
        this.state.selectedSeason = e.target.value;
        this.fetchVoucherhistory();
    }



    render() {
        console.log(this.state.VoucherData);
        return (
            this.state.VoucherData.map((item, i) => (
                <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col float-start">
                            <h1 className="left">Voucher History</h1>
                        </div>
                        {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ?
                            <div className="ms-Grid-col float-end">
                                <div className="ms-Grid-row">
                                    <ButtonGroup>
                                        <select className="mld-ddl wld-primary-button" style={{ opacity: "1" }} name="SeasonName" id="season" onChange={this.handleSeasonChange}>
                                            {this.state.seasonlist?.map((item) => (
                                                <option selected={item.id == 0 ? true : false} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                        <CsvDownload className="wld-primary-button btn me-3" style={{ padding: "0.2rem 0.75rem" }} filename={"Voucher_History" + moment(new Date()).format("YYYYMMDDhhmmss") + ".csv"} data={this.state.voucherHistoryForExport}>Export</CsvDownload>
                                        {/*<PrimaryButton className="wld-primary-button btn me-3" text="Export" onClick={() => this.showModal(item.ID)} />*/}
                                    </ButtonGroup>

                                </div>
                            </div>
                            : ""
                        }
                    </div>
                    <div className="ms-Grid-row CardBox">
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 _Card">
                            <div className="Card">
                                <h1 class="customCardtext"> Remaining Vouchers</h1>
                                <span className="CardLabel">{item.RemainingCount}</span>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 _Card customcard">
                            <div className="Card">
                                <h1 class="customCardtext">Pending Vouchers</h1>
                                <span className="CardLabel">{item.PendingCount}</span>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 _Card customcard">
                            <div className="Card">
                                <h1 class="customCardtext">Approved Vouchers</h1>
                                <span className="CardLabel">{item.ApprovedCount}</span>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 _Card">
                            <div className="fCard Card">
                                <VoucherRequestHistory getVoucherRequested={this.getVoucherRequested} employeeId={this.props.auth.UserID} RoleID={this.state.RoleID} historyData={item.VoucherHistory} />
                            </div>
                        </div>
                    </div>
                </div>
            ))
        );
    }
}