import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import { PrimaryButton } from '@fluentui/react';

const Confirmation = ({ show, proceed, dismiss, cancel, confirmation, title,
    okText, cancelText, okButtonStyle, cancelButtonStyle, ...options }) => {
    const header = title ? (
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
    ) : undefined;
    return (
        <Modal
            size="small"
            show={show}
            onHide={() => proceed(false)}
            backdrop="static"
        >
            {header}
            <Modal.Body className="ModalContentBox">
                <div className="row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">{confirmation}</div>
                </div>
                <div className="row  center mld-row mt-4">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"><PrimaryButton positive onClick={() => proceed(false)} className="wld-secondary-button">{cancelText}</PrimaryButton></div>
                    <div className="col-md-3"><PrimaryButton onClick={() => proceed(true)} className="wld-primary-button">{okText}</PrimaryButton></div>
                    <div className="col-md-3"> </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

Confirmation.propTypes = {
    /** header title */
    title: PropTypes.string,
    confirmation: PropTypes.string, // arguments of your confirm function
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger',
        'warning', 'info', 'light', 'dark', 'link']),
    cancelButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger',
        'warning', 'info', 'light', 'dark', 'link']),
    show: PropTypes.bool, // from confirmable.
    proceed: PropTypes.func, // from confirmable.
    cancel: PropTypes.func, // from confirmable.
    dismiss: PropTypes.func, // from confirmable.
};

Confirmation.defaultProps = {
    title: undefined,
    confirmation: undefined,
    okText: 'OK',
    cancelText: 'Cancel',
    okButtonStyle: 'primary',
    cancelButtonStyle: 'secondary',
    show: undefined,
    proceed: undefined,
    cancel: undefined,
    dismiss: undefined,
};

const confirmLow = createConfirmation(confirmable(Confirmation));

export const confirm = (message, options = {}) => {
    return confirmLow(Object.assign({ confirmation: message }, options));
};

