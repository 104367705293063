import { Button } from 'bootstrap';
import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider"


export class RequestAccess extends Component {
    static displayName = RequestAccess.name;

    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            Usermodal: {
                UserName: this.props.msalContext.instance.getAllAccounts()[0] != undefined ? this.props.msalContext.instance.getAllAccounts()[0].username : null ,
                ExternalIdentifier: this.props.msalContext.instance.getAllAccounts()[0] != undefined ? this.props.msalContext.instance.getAllAccounts()[0].localAccountId : null, 
            }
        };
        this.sendAccessRequest = this.sendAccessRequest.bind(this);
        console.log(this.props);
    }

    componentDidMount() {
    }

    
    //PS:SC to send the access request
    sendAccessRequest() {
        let requestData = JSON.stringify(this.state.Usermodal);
        console.log(requestData);
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0] //get tocken to access the api
        }).then((response) => {
            fetch('Account/RequestAccess', prepareHeader(response, "POST", requestData)).then(response => {
                if (response.status == 200) {
                    global.toastMeForSuccess("Request sent successfully")
                } else {
                    global.toastMeForSuccess("Error:" + response.status)
                }
                return response.json();
            }).then(data => {
                console.log("temp:" + data);
            });
        });
        
    }


    render() {
        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                <div className="ms-Grid-row ModalCard">
                    <h3>Sorry, you do not have access to this site.</h3>
                    <textarea defaultValue="I'd like access please."></textarea>
                    <PrimaryButton onClick={this.sendAccessRequest} className="wld-primary-button">Request Access</PrimaryButton>
                </div>
            </div>
        );
    }
}
