import React, { Component, useState } from 'react';
import { PrimaryButton,IconButton } from '@fluentui/react';
import { Modal, Button } from "react-bootstrap";
import { SeasonRequestModel } from './SeasonRequestModel';
import moment from 'moment';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider"
import { NavMenu } from "./NavMenu";
import { trackPromise } from 'react-promise-tracker';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

export class Season extends Component {
    static displayName = Season.name;

    constructor(props) {
        super(props);
        this.state = {
            isSModalOpen: false,
            seasonData: [],
            employeeID: props.auth.UserID,
            seasonID: 0,
            sortType: 'ascending',
            key: null,
            sortIcon: 'SortUp',
            RoleID: props.auth.RoleID
        };

        this.fetchSeason = this.fetchSeason.bind(this);

        this.showSModal = this.showSModal.bind(this);
        this.hideSModal = this.hideSModal.bind(this);
         initializeIcons();
    }

    componentDidMount() {
        if (this.state.RoleID.some(s=> Roles.userRoles.includes(s))) {
            global.toastMeForError("Permission denied")
            this.props.history.push('/');
        }
        this.fetchSeason();
    }

    //PS:SC To fetch season.
    fetchSeason() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
            fetch('Season/FetchSeason', prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
                this.setState({ seasonData: JSON.parse(data) });
            }));
        });
    }

    //For Edit Season
    showSModal(id) {
        this.setState({ isSModalOpen: true });
        this.state.seasonID = id;
    }
    hideSModal() {
        this.setState({ isSModalOpen: false });
    }

    requestSort = (key) => {
        this.setState({ key: key });
        if (this.state.sortType === null || this.state.sortType === 'descending')
            this.setState({ sortType: 'ascending', sortIcon: 'SortUp' });
        if (this.state.sortType === 'ascending') {
            this.setState({ sortType: 'descending', sortIcon: 'SortDown' });
        }
    };

    SetActive(item) {
        if (item.IsActive == 0) {
            item.IsActive = 1;
        } else {
            item.IsActive = 0;
        }
        let data = JSON.stringify(item);
        console.log(data);
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0] //get tocken to access the api
        }).then((response) => {
            trackPromise(
            fetch('Season/AddEditSeason' , prepareHeader(response, "POST", data))
            .then((response) => response.json())
            .then(data => {
                if (data == "Added" || data == "Updated") {
                    global.toastMeForSuccess('Season '+data+' Successfully')
                    this.fetchSeason();
                } else {
                    global.toastMeForError(data);
                }
            }));
        });
       
    }


    render() {

        this.state.seasonData.sort((a, b) => {
            if (a[this.state.key] < b[this.state.key]) {
                return this.state.sortType === 'ascending' ? -1 : 1;
            }
            if (a[this.state.key] > b[this.state.key]) {
                return this.state.sortType === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button float-start" onClick={() => this.showSModal(0)}>Add Season</PrimaryButton> : ""}
                <div className="fCard Card tableborder">
                <table className="mld-table table">
                    <thead>
                        <tr>
                            <th scope="col"  className = "left" onClick={() => this.requestSort('SeasonName')}>
                                Season Name
                                {this.state.key == "SeasonName" ?
                                        <IconButton
                                            iconProps={{ iconName: this.state.sortIcon }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    }
                                                }
                                            }} />
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }
                            </th>
                            <th scope="col" onClick={() => this.requestSort('StartDate')}>
                                Start Date
                                {this.state.key == "StartDate" ?
                                        <IconButton
                                            iconProps={{ iconName: this.state.sortIcon }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    }
                                                }
                                            }} />
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }
                            </th>
                            <th scope="col" onClick={() => this.requestSort('EndDate')}>
                                End Date
                                {this.state.key == "EndDate" ?
                                        <IconButton
                                            iconProps={{ iconName: this.state.sortIcon }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    }
                                                }
                                            }} />
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }
                            </th>
                            <th scope="col">Active</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.seasonData.map((item, i) => (
                            <tr data-id={item.Id}>
                                <td className="left">{item.SeasonName}</td>
                                <td>{moment(item.StartDate).format("MM/DD/YYYY")}</td>
                                <td>{moment(item.EndDate).format("MM/DD/YYYY")}</td>
                                <td>{this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ? <input type="checkbox" onClick={() => this.SetActive(item)} disabled={ item.IsActive == 1 ? true : false } checked={item.IsActive == 1 ? true : false} /> : ""}</td>
                                <td>{this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button" text="Edit Season" onClick={() => this.showSModal(item.Id)} /> : ""}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                {this.state.isSModalOpen ? (
                    <Modal id="hideSModal" className="wld-Modal-md" size="lg" show={this.state.isSModalOpen} onHide={this.hideSModal}>
                        <Modal.Body className="ModalContentBox">
                            <SeasonRequestModel seasonID={this.state.seasonID} employeeID={ this.state.employeeID } hideSModal={this.hideSModal} fetchSeason={this.fetchSeason} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.state.seasonData.seasonID)
                )}


            </div>
        );
    }
}