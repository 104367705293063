import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import axios from 'axios';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider"
import { trackPromise } from 'react-promise-tracker';
import validator from 'validator';
import AsyncSelect from 'react-select/async';
import { DefaultButton } from '@fluentui/react';
import { confirm } from './ConfirmationModal';
import { statusColors } from './Games';

export class VoucherRequestModal extends Component {
    static displayName = VoucherRequestModal.name;
    static dropdownControlledExampleOptions = [
        { key: 'Good', text: 'Good' },
        { key: 'LimitReached', text: 'Limit Reached' },
        { key: 'Poor', text: 'Poor' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            gameID: props.gameID
            , employeeID: props.employeeID
            , voucherData: {
                DateRequested: '',
                RequestDeadline: '',
                Opponent: '',
                GameTime: '',
                GameStatus: '',
                VouncherRequested: 1,
                AccessibleSeating: false,
                FirstName: '',
                LastName: '',
                Email: '',
                TxtMessages: false,
                PhoneNumber: '',
                //Carrier: '',
                MaxVouchers: 6,
                Season: '',
                AssignedTo: props.employeeID
            }
            , currentDateTime: Date().toLocaleString()
            , carriersList: [
                { key: 'AT&T Wireless', value: 'ATT Wireless' },
                { key: 'Sprint', value: 'Sprint' },
                { key: 'T-Mobile', value: 'T-Mobile' },
                { key: 'Verizon', value: 'Verizon' },
                { key: 'Virgin Mobile', value: 'Virgin Mobile' },
                { key: 'Boost Mobile', value: 'Boost Mobile' },
                { key: 'Altel Wireless', value: 'Altel Wireless' },
                { key: 'Qwest Mobile', value: 'Qwest Mobile' }]
            , assignToOption: [
                { name: "self", value: props.employeeID },
            ],
            RoleId: props.RoleID,
            selectedVoucherId: props.voucherID
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.phoneMask = this.phoneMask.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    handleValidation(event) {
        document.getElementById("txtMsg").classList.remove("input-error");
        document.getElementById("phonenumber").classList.remove("input-error");
        /*document.getElementById("carrier").classList.remove("input-error");*/
        if (this.state.voucherData != undefined) {
            if (this.state.voucherData.TxtMessages == "" || this.state.voucherData.TxtMessages == undefined) {
                document.getElementById("txtMsg").classList.add("input-error");
            }
            if (!this.validatePhone(this.state.voucherData)) {
                document.getElementById("phonenumber").classList.add("input-error");
            }
            //if (this.state.voucherData.Carrier == "" || this.state.voucherData.Carrier == undefined) {
            //    document.getElementById("carrier").classList.add("input-error");
            //}
            if ((this.validatePhone(this.state.voucherData))
                /*&& this.state.voucherData.Carrier != ""*/) {
                return true;
            } else {
                return false;
            }
        } else {
            global.toastMeForWarning("Please fill the form")
        }
    }


    handleSubmit(event) {
        this.state.voucherData.VouncherRequested = document.getElementById("vouncherRequested").value;
        this.state.voucherData.AccessibleSeating = document.getElementById("spacialReq").checked.toString();
        this.state.voucherData.TxtMessages = document.querySelector('input[id="txtmessages"]:checked').value;
        this.state.voucherData.PhoneNumber = document.getElementById("phonenumber").value;
        let data = JSON.stringify(this.state.voucherData);
        if (validator.isEmail(this.state.voucherData.Email) && this.state.voucherData.Email != "") {
            if (this.handleValidation(event)) {
                msalAuth.acquireTokenSilent({
                    ...APIAuth,
                    account: msalAuth.getAllAccounts()[0]
                }).then((response) => {
                    if (this.props.voucherID != undefined) {
                        trackPromise(fetch('Game/UpdateVoucherRequest', prepareHeader(response, "POST", data))
                            .then((response) => response.json())
                            .then(result => {
                                /*var result = JSON.parse(data);*/
                                if (result.statusCode == 200) {
                                    global.toastMeForSuccess(result.value.message);
                                    this.props.fetchVouchers();
                                    this.props.hideVModal();
                                } else {
                                    global.toastMeForError(result.value.message);
                                }
                            }).catch(error => {
                                global.toastMeForError(error.message);
                            }));
                    }
                    else {
                        trackPromise(fetch('Game/AddVoucherRequest', prepareHeader(response, "POST", data))
                            .then((response) => response.json())
                            .then(result => {
                                /*var result = JSON.parse(data);*/
                                if (result == true) {
                                    global.toastMeForSuccess('Voucher Requested Successfully')
                                    this.props.fetchGames();
                                    this.props.hideVModal();
                                } else {
                                    global.toastMeForError(result)
                                }
                            }));
                    }
                });
            }

        } else {
            global.toastMeForWarning("Please enter a valid email.")
        }
        event.preventDefault();
    }

    handleChange = (e) => {
        if(e.target.type == "checkbox"){
            e.target.value = e.target.checked.toString()
        }
        this.setState(prevState => ({
            voucherData: {
                ...prevState.voucherData,
                [e.target.name]: e.target.value
            }
        }))

    }

    getDetails() {
        if (this.props.voucherID != undefined) {
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                fetch('Game/GetVoucherById?id=' + this.props.voucherID, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(result => {
                        var data = JSON.parse(result.data)
                        this.setState(prevState => ({
                            voucherData: data,
                            assignToOption: {
                                ...prevState.assignToOption,
                                name: data.Email
                            }
                        }));
                    });
            });
        }
        else {
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                fetch('Game/getVoucherDetails?employeeID=' + this.state.employeeID + '&gameID=' + this.state.gameID, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(data => {
                        var result = JSON.parse(data);
                        this.setState(prevState => ({
                            voucherData: result[0],
                            assignToOption: {
                                ...prevState.assignToOption,
                                name: result[0].Email
                            }
                        }));
                    });
            });
        }


    }

    onChangeNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(prevState => ({
                voucherData: {
                    ...prevState.voucherData,
                    [e.target.name]: e.target.value
                }
            }))
        } else {
            e.target.value = null;
        }
    }

    getAssignToOptions = (query) => {
        return new Promise((resolve, reject) => {
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                fetch('Employee/searchEmployee?query=' + query, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(data => {
                        resolve(
                            data.map(({ name, value }) => ({
                                value: value,
                                name: name,
                            })),
                        );
                    })
                    .catch(reject);
            });
        });
    };

    handleAssignTochange = (value) => {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Employee/fetchEmployeebyId?employeeID=' + value.value, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    this.setState(prevState => ({
                        voucherData: {
                            ...prevState.voucherData,
                            AssignedTo: result.Id,
                            FirstName: result.FirstName,
                            LastName: result.LastName,
                            PhoneNumber: result.PhoneNumber,
                            TxtMessages: result.TxtMessages.toString(),
                            //Carrier: result.Carrier
                        },
                        assignToOption: value
                    }))
                });
        });
    }

    styles = {
        option: (provided, state) => ({
            ...provided,
            color: "#4ED19F",
            backgroundColor: "rgba(21, 71, 52, 0.9)",
            "&:hover": {
                backgroundColor: "#797775"
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#4ED19F"
        }),
        input: (provided, state) => ({
            ...provided,
            color: "#4ED19F"
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#4ED19F"
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "rgba(21, 71, 52, 0.9)",
        }),
        control: (provided, state) => ({
            ...provided,
            color: "#4ED19F",
            backgroundColor: "rgba(21, 71, 52, 0.9)",
            opacity: "0.5",
            minHeight: '32px',
            height: '32px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '32px',
            padding: '0 6px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),

    };

    validatePhone(el) {
        var isvalid = true;
        if (el.TxtMessages.toLowerCase() == "true") {
            if (el.PhoneNumber == "" || el.PhoneNumber == undefined || el.PhoneNumber.length != 13) {
                isvalid = false;
            }
        } else if (el.TxtMessages.toLowerCase() == "false" && el.PhoneNumber != "" && el.PhoneNumber.length != 13) {
            isvalid = false;
        }
        return isvalid;
    }

    async deleteVoucher(id) {
        const result = await confirm('Vouchers requested will get deleted for this game. Are you sure?');
        if (result == true) {
            //this.props.hideGModal();
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0] //get tocken to access the api
            }).then((response) => {
                fetch('Game/DeleteVoucherRequest?voucherId=' + id, prepareHeader(response, "DELETE"))
                    .then((response) => response.json())
                    .then(result => {
                        if (result.statusCode == 200) {
                            global.toastMeForSuccess(result.value.message);
                            this.props.fetchVouchers();
                            this.props.hideVModal();
                        } else {
                            global.toastMeForError(result.value.message);
                        }
                    });
            });
        }

    }

    phoneMask(e) {
        var phoneValue = this.formatPhoneNumber(e.target.value);
        e.target.value = phoneValue;
    }

    formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early

        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }


    render() {
        var isAccSeating = false;
        var txtMsg = false;
        if(this.state.voucherData?.AccessibleSeating.toString().toLowerCase() === "true"){
            isAccSeating = true
        }
        
        if(this.state.voucherData?.TxtMessages.toString().toLowerCase() === "true"){
            txtMsg = true
        }
            
        return (

            <div>
                <div>
                    <h1>{this.props.voucherID != undefined ? "Edit Vouchers" : "Request Vouchers"}</h1>
                    <div className="row mld-alter-row left mld-row mt-4">
                        <div className="col-sm-1"></div>
                        <div className="col-md-5 left">Date Requested</div>
                        <div className="col-md-4 mld-text"> {this.state.voucherData?.DateRequested} </div>
                        <div className="col-md-3"> </div>
                    </div>
                    {/*<div className="row left mld-row">*/}
                    {/*    <div className="col-md-1"></div>*/}
                    {/*    <div className="col-md-5 left">Request Deadline</div>*/}
                    {/*    <div className="col-md-3 mld-text">{this.state.voucherData?.RequestDeadline} </div>*/}
                    {/*    <div className="col-md-3"> </div>*/}
                    {/*</div>*/}
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Opponent</div>
                        <div className="col-md-3 mld-text"> {this.state.voucherData?.Opponent} </div>
                        <div className="col-md-3"> </div>
                    </div>
                    <div className="row  left mld-alter-row mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Game Time</div>
                        <div className="col-md-3 mld-text"> {this.state.voucherData?.GameTime}</div>
                        <div className="col-md-3"> </div>
                    </div>
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Game Status</div>
                        <div className="col-md-3">  <label className={statusColors.find(c => c.value === this.state.voucherData?.GameStatus)?.Color || ''} defaultValue={this.state.voucherData?.GameStatus}> {this.state.voucherData?.GameStatus}</label> </div>
                        <div className="col-md-3"> </div>
                    </div>

                    <div className="row mld-alter-row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5">Vouchers Requested</div>
                        <div className="col-md-3">
                            <select className="mld-ddl" name="VouncherRequested" id="vouncherRequested" onChange={this.handleChange}>
                                {
                                    Array(this.state.voucherData?.MaxVouchers).fill(1).map((el, i) =>
                                        <option selected={i == this.state.voucherData?.VouncherRequested - 1 ? true : false} defaultValue={this.state.voucherData?.VouncherRequested}>{i + 1}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-md-3"> </div>
                    </div>
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Special Requests</div>
                        <div className="col-md-3"><input type="checkbox" id="spacialReq" name="AccessibleSeating" defaultValue={isAccSeating} checked={isAccSeating} onChange={this.handleChange} className="form-check-input mld-checK" /> <label>Accessible Seating</label> </div>
                        <div className="col-md-3"> </div>
                    </div>
                    {this.state.RoleId.some(s => Roles.generalAdmins.includes(s)) && this.props.voucherID == undefined ?
                        <div className="row mld-alter-row  left mld-row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5 left">Assign to</div>
                            <div className="col-md-4">   <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={this.state.assignToOption}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e.id}
                                loadOptions={this.getAssignToOptions}
                                onChange={this.handleAssignTochange}
                                styles={this.styles}
                                id="email"
                            />
                            </div>
                            <div className="col-md-2"> </div>
                        </div>
                        : ""}
                    <div className="row left" style={{ display: 'none' }}>
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Will Call First Name</div>
                        <div className="col-md-3"> <input type="text" name='FirstName' maxLength="20" onChange={this.handleChange} className="mld-textBox-noBorder ms-TextField-field" defaultValue={this.state.voucherData.FirstName} /> </div>
                        <div className="col-md-3"> </div>
                    </div>
                    <div className="row left" style={{ display: 'none' }}>
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Will Call Last Name</div>
                        <div className="col-md-3"> <input type="text" name='LastName' maxLength="20" onChange={this.handleChange} className="mld-textBox-noBorder ms-TextField-field" defaultValue={this.state.voucherData.LastName} /> </div>
                        <div className="col-md-3"> </div>
                    </div>
                    <div className="row left mld-row" style={this.state.RoleId.some(s => Roles.generalAdmins.includes(s)) && this.props.voucherID == undefined ? { display: 'none' } : { display: 'flex' }}>
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Email</div>
                        <div className="col-md-3 mld-text"> {this.state.voucherData.Email} </div>
                        <div className="col-md-3"> </div>
                    </div>
                    {this.state.RoleId.some(s => Roles.generalAdmins.includes(s)) && this.props.voucherID == undefined ?
                        <div className="mld-row" style={{ display: 'none' }}></div>
                        : ""}
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Text Messages</div>
                        <div className="col-md-4">
                            <div className="row" id="txtMsg">
                                <div className="col-md-6 mld-text">
                                    <input type="radio" id="msgOn" id="txtmessages" name="TxtMessages" checked={txtMsg} value={true} className="form-check-input mld-radio" onChange={this.handleChange} /> <label>On</label>
                                </div>
                                <div className="col-md-6 mld-text">
                                    <input type="radio" id="msgOff" id="txtmessages" name="TxtMessages" checked={!txtMsg} value={false} className="form-check-input mld-radio" onChange={this.handleChange} /> <label>Off</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"> </div>
                    </div>
                    <div className="row left mld-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 left">Cell Phone</div>
                        <div className="col-md-3"> <input type="text" id="phonenumber" name="PhoneNumber" onChange={this.phoneMask} maxLength="13" minLength="13" className="mld-textBox-noBorder ms-TextField-field" defaultValue={this.state.voucherData?.PhoneNumber} /> </div>
                        <div className="col-md-3"> </div>
                    </div>
                    {/*<div className="row mld-alter-row left mld-row">*/}
                    {/*    <div className="col-md-1"></div>*/}
                    {/*    <div className="col-md-5">Carrier</div>*/}
                    {/*    <div className="col-md-3">*/}
                    {/*        <select className="mld-ddl" id="carrier" name='Carrier' onChange={this.handleChange} defaultValue={this.state.voucherData?.Carrier} >*/}
                    {/*            {this.state.carriersList.map((m, i) => {*/}
                    {/*                return <option value={m.value} selected={this.state.voucherData?.Carrier == m.value ? true : false}> {m.value} </option>*/}
                    {/*            })}*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-3"> </div>*/}
                    {/*</div>*/}

                    <div className="row  center mld-row mt-4 d-flex justify-content-center">
                        <div className="col-md-3"><PrimaryButton positive onClick={this.handleSubmit} className="wld-primary-button">{this.props.voucherID != undefined ? "Update" : "Request"}</PrimaryButton></div>
                        <div className="col-md-3"><PrimaryButton onClick={this.props.hideVModal} className="wld-secondary-button">Cancel</PrimaryButton></div>

                        {this.props.voucherID != undefined ?
                            <div className="col-md-3">
                                <DefaultButton onClick={() => this.deleteVoucher(this.props.voucherID)} className="gameRemoveButton" title="Delete voucher request.">Delete</DefaultButton>
                            </div>
                            : ""}
                    </div>

                </div>
            </div>
        );
    }
}
