import React, { Component } from 'react';
import { toast } from 'wc-toast';

global.toastMeForSuccess = function (Msg) {
    toast.success(Msg);
}

global.toastMeForError = function (Msg) {
    toast.error(Msg);
}

global.toastMeForWarning = function (Msg) {
    toast(Msg, { icon: { type: 'custom', content: '⚠️' }});
}