import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import moment from 'moment';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider"



export class SeasonRequestModel extends Component {
    static displayName = SeasonRequestModel.name;

    constructor(props) {
        super(props);
        this.state = {
            seasonID: props.seasonID
            , employeeID: props.employeeID
            , seasonData: {
                SeasonName: '',
                StartDate: '',
                EndDate: '',
                IsActive: ''
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        console.log(this.state);
    }

    componentDidMount() {
        this.getDetails();
    }


    handleSubmit(event) {
        if (this.handleValidation(event)) {
            let data = JSON.stringify(this.state.seasonData);
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0] //get tocken to access the api
            }).then((response) => {
                fetch('Season/AddEditSeason', prepareHeader(response, "POST", data))
                    .then((response) => response.json())
                    .then(data => {
                        if (data == "Added" || data == "Updated") {
                            this.props.hideSModal();
                            global.toastMeForSuccess('Season ' + data + ' Successfully')
                            this.props.fetchSeason();
                        } else {
                            global.toastMeForError(data);
                        }
                    });
            });
        }
        event.preventDefault();
    }

    handleChange = (e) => {
        //var value = e.target.type == "date" ? e.target.value + "T12:00:00.000" : e.target.value;
        this.setState(prevState => ({
            seasonData: {
                ...prevState.seasonData,
                [e.target.name]: e.target.value
            }
        }))

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        const checked = target.checked;
        if (checked) {
            this.state.seasonData.IsActive = 1;
        } else {
            this.state.seasonData.IsActive = 0;
        }
    }

    getDetails() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Season/FetchSeasonById?employeeID=' + this.state.employeeID + '&seasonID=' + this.state.seasonID, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    this.setState({ seasonData: result[0] });
                    console.log(this.state.seasonData);
                });
        });

    }

    handleValidation(event) {
        document.getElementById("seasonname").classList.remove("input-error");
        document.getElementById("startdate").classList.remove("input-error");
        document.getElementById("enddate").classList.remove("input-error");
        if (this.state.seasonData != undefined) {
            if (this.state.seasonData.SeasonName == "" || this.state.seasonData.SeasonName == undefined) {
                document.getElementById("seasonname").classList.add("input-error");
            }
            if (this.state.seasonData.StartDate == "" || this.state.seasonData.StartDate == undefined) {
                document.getElementById("startdate").classList.add("input-error");
            }
            if (this.state.seasonData.EndDate == "" || this.state.seasonData.EndDate == undefined) {
                document.getElementById("enddate").classList.add("input-error");
            }
            if (this.state.seasonData.StartDate > this.state.seasonData.EndDate) {
                document.getElementById("startdate").classList.add("input-error");
                document.getElementById("enddate").classList.add("input-error");
                global.toastMeForError("Start date can not be greater than the end date.")
                return false;
            }
            if (this.state.seasonData.SeasonName != "" && this.state.seasonData.SeasonName != undefined &&
                this.state.seasonData.StartDate != "" && this.state.seasonData.StartDate != undefined &&
                this.state.seasonData.EndDate != "" && this.state.seasonData.EndDate != undefined) {
                return true;
            }
        } else {
            global.toastMeForWarning("Please fill the form")
        }
        event.preventDefault();
    }


    render() {
        return (

            <div>
                <div className="row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"><h5>{this.state.seasonID == 0 ? "Add Season" : "Edit Season"}</h5></div>
                </div>
                <div className="row mld-alter-row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Season Name</lable></div>
                    <div className="col-md-3"> <input type="text" id="seasonname" name="SeasonName" onChange={this.handleChange} className="mld-textBox-noBorder ms-TextField-field mt-2" placeholder="Enter season" defaultValue={this.state.seasonData?.SeasonName} /></div>
                </div>
                <div className="row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Start Date</lable></div>
                    <div className="col-md-3"> <input type="date" id="startdate" name="StartDate" onChange={this.handleChange} className="mld-input form-control" value={this.state.seasonData?.StartDate} /> </div>
                </div>
                <div className="row left mld-row mld-alter-row">
                    <div className="col-md-6"><lable className="ms-5">End Date</lable></div>
                    <div className="col-md-3"> <input type="date" id="enddate" name="EndDate" onChange={this.handleChange} className="mld-input form-control" value={this.state.seasonData?.EndDate} /> </div>
                </div>
                <div className="row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Active</lable></div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-6  mld-text">
                                <input
                                    name="IsActive"
                                    type="checkbox"
                                    checked={this.state.seasonData?.IsActive == 1 ? true : false}
                                    onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"> </div>
                </div>
                <div className="row  center mt-4">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"><PrimaryButton positive onClick={this.handleSubmit} className="wld-primary-button">Save</PrimaryButton></div>
                    <div className="col-md-3"><PrimaryButton onClick={this.props.hideSModal} className="wld-secondary-button">Cancel</PrimaryButton></div>
                    <div className="col-md-3"> </div>
                </div>
            </div>
        );
    }
}
