import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider"


export class Maintenance extends Component {
    static displayName = Maintenance.name;

   

    constructor(props) {
        super(props);
        this.state = {
            maintenanceData:null
        }
        this.handleMaintenance = this.handleMaintenance.bind(this);
        this.getMaintenanceDetails = this.getMaintenanceDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        console.log(props);
    }

    componentDidMount() {
        if (this.props.auth.RoleID.some(s => Roles.userRoles.includes(s))) {
            global.toastMeForError("Permission denied")
            this.props.history.push('/');
        }
        this.getMaintenanceDetails();
    }


    handleMaintenance(event) {
        const startdate = document.getElementById("startdate").value;
        const enddate = document.getElementById("enddate").value;
        if (this.handleValidation(startdate, enddate)) {
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                fetch('Maintenance/GetMaintenanceDate?startDate=' + startdate + '&endDate=' + enddate, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    //var result = JSON.parse(data);
                    global.toastMeForSuccess("System active date time updated.")
                });
            });
        }

        event.preventDefault();
    }

    handleValidation(startdate, enddate) {
        document.getElementById("startdate").classList.remove("input-error");
        document.getElementById("enddate").classList.remove("input-error");
        if (startdate != "" || enddate != "") {
            const startDate = new Date(startdate);
            const endDate = new Date(enddate);
            console.log(startDate.getDate());
            if (startDate > endDate) {
                document.getElementById("startdate").classList.add("input-error");
                document.getElementById("enddate").classList.add("input-error");
                global.toastMeForError("Start date can not be less than end date.")
                return false;
            } else {
                return true;
            }
        } else {
            global.toastMeForWarning("Please fill the form.")
            return false;
        }
    }

    getMaintenanceDetails() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Maintenance/getCurrentActiveDates', prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    this.setState({
                        maintenanceData: data
                    })
                });
        });
    }

    handleChange = (e) => {
        var value = e.target.type == "date" ? e.target.value + "T12:00:00.000" : e.target.value;
        this.setState(prevState => ({
            maintenanceData: {
                ...prevState.maintenanceData,
                [e.target.name]: value
            }
        }))

    }

    render() {
        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                <h4 className="text-white left">Active Date Range</h4>
                <div className="ms-Grid-row ModalCard">
                    <h5 className="ms-4 mt-4">This will set the date range the system is active.</h5>
                    <div className="row mt-3">
                        <div className="col-md-6 center">
                            <h5 className="mld-text-gold tdTitle">Begin Active Date:</h5>
                        </div>
                        <div className="col-md-3">
                            <input type="date" id="startdate" name="startDate" onChange={this.handleChange} value={(new Date(this.state.maintenanceData?.startDate)).toLocaleDateString('en-CA')} className="mld-input form-control" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <h5 className="mld-text-gold ms-3 tdTitle">End Active Date:</h5>
                        </div>
                        <div className="col-md-3">
                            <input type="date" id="enddate" name="endDate" onChange={this.handleChange} value={(new Date(this.state.maintenanceData?.endDate)).toLocaleDateString('en-CA')}  placeholder="Select a date.." className="mld-input form-control" />
                        </div>
                    </div>
                    <div className="row center mt-5 mb-5">
                        <div className="col-md-4"></div>
                        <div className="col-md-3"><PrimaryButton onClick={this.handleMaintenance} className="wld-primary-button w-50">Save</PrimaryButton></div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3"> </div>
                    </div>
                </div>
            </div>
        );
    }
}