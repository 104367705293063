import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider"
import { confirm } from './ConfirmationModal';
import moment from 'moment';
import { statusColors } from './Games';
const momenttz = require('moment-timezone');

export function dateCheck(from, to, check) {

    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}

export class EditGames extends Component {
    static displayName = EditGames.name;

    constructor(props) {
        super(props);
        this.state = {
            gameID: props.gameID
            , employeeID: props.employeeID
            , seasonData: []
            , gamesData: {
                ID: '',
                GameTitle: '',
                GameTime: '',
                Status: '',
                RequestDeadline: '',
                MaxVouchers: '',
                SeasonName: '',
                TotalVouchers: ''
            },
            RoleID: props.RoleID,
            activeSeason: null
        };
        this.handleGSubmit = this.handleGSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        console.log(this.state);
    }

    componentDidMount() {
        this.getGameDetails();
        this.fetchSeason();
    }

    //PS:SC To fetch games.
    getGameDetails() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Game/fetchGamesyId?employeeID=' + this.state.employeeID + '&gameID=' + this.state.gameID, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    this.setState({ gamesData: result[0] });
                    console.log(this.state.gamesData);
                });
        });
    }

    fetchSeason() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Season/FetchSeason', prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    this.setState({ seasonData: JSON.parse(data), activeSeason: result.filter(s => s.IsActive == 1)[0] });
                    console.log(this.state.activeSeason);
                });
        });
    }

    handleGSubmit(event) {
        if (this.state.gamesData != undefined) {
            this.state.gamesData.Status = document.getElementById("status").value;
            this.state.gamesData.SeasonName = document.getElementById("season").value;
            this.state.gamesData.MaxVouchers = document.getElementById("maxvoucher").value;
            this.state.gamesData.TotalVouchers = document.getElementById("totalVouchers").value;
            if (this.handleValidation(event)) {
                this.props.hideGModal();
                let data = JSON.stringify(this.state.gamesData);
                msalAuth.acquireTokenSilent({
                    ...APIAuth,
                    account: msalAuth.getAllAccounts()[0] //get tocken to access the api
                }).then((response) => {
                    fetch('Game/AddEditGames', prepareHeader(response, "POST", data))
                        .then((response) => response.json())
                        .then(data => {
                            var result = data;
                            console.log(result);
                            if (result == true) {
                                global.toastMeForSuccess('Game Updated Successfully')
                                this.props.fetchGames();
                            } else {
                                global.toastMeForError('Application error occured: ' + result)
                            }
                        });
                });
            }
            event.preventDefault();
        } else {
            global.toastMeForWarning("Please fill the form")
        }
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            gamesData: {
                ...prevState.gamesData,
                [e.target.name]: e.target.value
            }
        }))
    }

    onChangeNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(prevState => ({
                gamesData: {
                    ...prevState.gamesData,
                    [e.target.name]: e.target.value
                }
            }))
        } else {
            e.target.value = null;
        }
    }

    handleValidation(event) {
        var currentDateTime = momenttz(new Date()).tz('America/Chicago');
        document.getElementById("opponent").classList.remove("input-error");
        document.getElementById("gametime").classList.remove("input-error");
        document.getElementById("status").classList.remove("input-error");
        document.getElementById("maxvoucher").classList.remove("input-error");
        document.getElementById("season").classList.remove("input-error");
        document.getElementById("requestdeadline").classList.remove("input-error");
        if (this.state.gamesData != undefined) {
            if (this.state.gamesData.GameTitle == "" || this.state.gamesData.GameTitle == undefined) {
                document.getElementById("opponent").classList.add("input-error");
            }
            if (this.state.gamesData.GameTime == "" || this.state.gamesData.GameTime == undefined) {
                document.getElementById("gametime").classList.add("input-error");
            }
            if (this.state.gamesData.Status == "" || this.state.gamesData.Status == undefined) {
                document.getElementById("status").classList.add("input-error");
            }
            if (this.state.gamesData.MaxVouchers == "" || this.state.gamesData.MaxVouchers == undefined) {
                document.getElementById("maxvoucher").classList.add("input-error");
            }
            if (this.state.gamesData.SeasonName == "" || this.state.gamesData.SeasonName == undefined) {
                document.getElementById("season").classList.add("input-error");
            }
            if (this.state.gamesData.RequestDeadline == "" || this.state.gamesData.RequestDeadline == undefined) {
                document.getElementById("requestdeadline").classList.add("input-error");
            }
            if (this.state.gamesData.TotalVouchers == "" || this.state.gamesData.TotalVouchers == undefined) {
                document.getElementById("totalVouchers").classList.add("input-error");
            }
            if (Date.parse(this.state.gamesData.GameTime) < Date.parse(this.state.gamesData.RequestDeadline)) {
                document.getElementById("gametime").classList.add("input-error");
                document.getElementById("requestdeadline").classList.add("input-error");
                global.toastMeForError('Game time cannot be less than request deadline')
                return false;
            }
            if (parseInt(this.state.gamesData.MaxVouchers) > parseInt(this.state.gamesData.TotalVouchers)) {
                document.getElementById("maxvoucher").classList.add("input-error");
                document.getElementById("totalVouchers").classList.add("input-error");
                global.toastMeForError('Max voucher can not be greater than total voucher')
                return false;
            }
            if (!dateCheck(this.state.activeSeason.StartDate, this.state.activeSeason.EndDate, this.state.gamesData.GameTime)) {
                document.getElementById("gametime").classList.add("input-error");
                document.getElementById("requestdeadline").classList.add("input-error");
                global.toastMeForError('The game time should fall between the start and end dates of the current season.');
                return false;
            }
            if (Date.parse(this.state.gamesData.GameTime) < Date.parse(currentDateTime)) {
                document.getElementById("gametime").classList.add("input-error");
                global.toastMeForError('The game time should be greater than current date');
                return false
            }
            if (this.state.gamesData.GameTitle != "" && this.state.gamesData.GameTitle != undefined &&
                this.state.gamesData.GameTime != "" && this.state.gamesData.GameTime != undefined &&
                this.state.gamesData.Status != "" && this.state.gamesData.Status != undefined &&
                this.state.gamesData.MaxVouchers != "" && this.state.gamesData.MaxVouchers != undefined &&
                this.state.gamesData.SeasonName != "" && this.state.gamesData.SeasonName != undefined &&
                this.state.gamesData.RequestDeadline != "" && this.state.gamesData.RequestDeadline != undefined &&
                this.state.gamesData.TotalVouchers != "" && this.state.gamesData.TotalVouchers != undefined) {
                return true;
            } else {
                return false;
            }
        } else {
            global.toastMeForWarning('Please fill the form.')
            return false;
        }
        event.preventDefault();
    }

    async deleteGame(id) {
        const result = await confirm('Vouchers requested would also get deleted for this game. Are you sure?');
        if (result == true) {
            this.props.hideGModal();
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0] //get tocken to access the api
            }).then((response) => {
                fetch('Game/DeleteGames?gameID=' + id, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(data => {
                        if (data == true) {
                            global.toastMeForSuccess('Game Updated Successfully')
                            this.props.fetchGames();
                        }
                    });
            });
        }

    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"><h5>{this.state.gameID == 0 ? "Add Game" : "Edit Game"}</h5></div>
                </div>
                <div className="row mld-alter-row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Opponent</lable></div>
                    <div className="col-md-6"> <input type="text" id="opponent" name="GameTitle" onChange={this.handleChange} className="mld-textBox-noBorder ms-TextField-field mt-2" placeholder="Enter opponent" defaultValue={this.state.gamesData?.GameTitle} required /> </div>
                </div>
                <div className="row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Game Time</lable></div>
                    <div className="col-md-3"> <input type="datetime-local" name="GameTime" style={{ width: "125%" }} onChange={this.handleChange} id="gametime" className="mld-input form-control" defaultValue={this.state.gamesData?.EditGameTime} required /> </div>
                </div>
                <div className="row mld-alter-row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Status</lable></div>
                    <div className="col-md-6">
                        <select className="mld-ddl mt-2" name="Status" id="status" onChange={this.handleChange}>
                            {statusColors.map(status => {
                                return (<option defaultValue={status.value} className={status.Color} selected={this.state.gamesData?.Status == status.value ? true : false}>{status.value}</option>)
                            })}
                            {/*<option defaultValue="Good" className="text-success" selected={this.state.gamesData?.Status == "Good" ? true : false}>Good</option>*/}
                            {/*<option defaultValue="Fair" style={{ color: "yellow" }} selected={this.state.gamesData?.Status == "Fair" ? true : false}>Fair</option>*/}
                            {/*<option defaultValue="Poor" className="text-danger" selected={this.state.gamesData?.Status == "Poor" ? true : false}>Poor</option>*/}
                            {/*<option defaultValue="At-limit" style={{ color: "black" }} selected={this.state.gamesData?.Status == "At-limit" ? true : false}>At-limit</option>*/}
                            {/*<option defaultValue="Not-Available" style={{ color: "gray" }} selected={this.state.gamesData?.Status == "Not-Available" ? true : false}>Not-Available</option>*/}
                        </select>
                    </div>
                </div>
                <div className="row  left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Max Voucher Per Game</lable></div>
                    <div className="col-md-3">
                        {/*{this.state.gameID != 0 ? (*/}
                        {/*    <select className="mld-ddl mt-2" name="MaxVouchers" id="maxvoucher" onChange={this.handleChange}>*/}
                        {/*        {*/}
                        {/*            Array(this.state.gamesData?.MaxVouchers).fill(1).map((el, i) =>*/}
                        {/*                <option selected={this.state.gamesData?.MaxVouchers == i + 1 ? true : false} defaultValue={this.state.gamesData?.MaxVouchers}>{i + 1}</option>*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </select>*/}
                        {/*) : (*/}
                        {/*    <input type="number" style={{ width: "25%" }} min="0" name="MaxVouchers" onChange={this.handleChange} id="maxvoucher" className="mld-input form-control" />*/}
                        {/*)}*/}
                        <input type="text" style={{ width: "55%" }} min="0" maxLength="3" name="MaxVouchers" onChange={this.onChangeNumber} id="maxvoucher" className="mld-input form-control" defaultValue={this.state.gamesData?.MaxVouchers} />

                    </div>
                </div>
                <div className="row mld-alter-row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Season</lable></div>
                    <div className="col-md-6">
                        <select className="mld-ddl mt-2" name="SeasonName" id="season" onChange={this.handleChange}>
                            {this.state.seasonData?.map((item) => (
                                <option selected={item.IsActive == 1 ? true : false} defaultValue={item.ID}>{item.SeasonName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Request Deadline</lable></div>
                    <div className="col-md-3"> <input type="datetime-local" style={{ width: "125%" }} name="RequestDeadline" onChange={this.handleChange} id="requestdeadline" className="mld-input form-control" defaultValue={this.state.gamesData?.EditRequestDeadline} /> </div>
                </div>
                <div className="row mld-alter-row left mld-row">
                    <div className="col-md-6"><lable className="ms-5">Total Vouchers</lable></div>
                    <div className="col-md-3"> <input type="text" min="0" maxLength="5" name="TotalVouchers" onChange={this.onChangeNumber} style={{ width: "55%" }} id="totalVouchers" className="mld-input form-control" defaultValue={this.state.gamesData?.TotalVouchers} /> </div>
                </div>
                <div className="row  center mld-row mt-4 d-flex justify-content-center">
                    <PrimaryButton positive onClick={this.handleGSubmit} style={{ width: "4%" }} className="wld-primary-button">Save</PrimaryButton>
                    <PrimaryButton onClick={this.props.hideGModal} style={{ width: "4%" }} className="wld-secondary-button">Cancel</PrimaryButton>
                    {this.state.gameID != 0 ?
                        <DefaultButton onClick={() => this.deleteGame(this.state.gameID)} className="gameRemoveButton" title="This will remove all voucher requests for this game.">Remove Game</DefaultButton>
                        : ""}
                </div>
            </div>
        );
    }
}