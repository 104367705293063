import { useState , useEffect } from "react";
import Papa from "papaparse";
import "../dataTable.css";
import { PrimaryButton } from '@fluentui/react';
import CsvDownload from "react-json-to-csv";
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider";
import { trackPromise } from 'react-promise-tracker';
import { getUserByID, getAllUsers } from '../graph/GraphService';
import validator from 'validator';
import { loginRequest } from '../msal/MsalConfig';

function ImportEmployees(props) {
    // State to store parsed data
    const [parsedData, setParsedData] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);

    //State to store the values
    const [values, setValues] = useState([]);

    const [allAdUsers, setAllAdUsers] = useState([]);

    const [isValidPhone, setIsValidPhone] = useState(true);

    let phoneVal = ''

    const jsonArrayTemplate = [
        {
            "FirstName": "Test",
            "LastName": "Test",
            "Email": "Test@wild.com",
            "IsAdmin (Yes/No)": "Yes/No",
            "TotalVouchers": 0,
            "TxtMessages": "Yes/No",
            "PhoneNumber": 1234567890,
            //"Carrier": "ATT Wireless",
            "Active (Yes/No)": "Yes/No"
        }
    ]

    //  useeffect(() => {
    //    // update the document title using the browser api
    //    msalauth.acquiretokensilent({
    //        ...loginrequest,
    //        account: msalauth.getallaccounts()[0]
    //    }).then((response) => {
    //        getallusers(response).then(result => {
    //            setalladusers(result.value);
    //        });
            
    //    });
    //});


    const changeHandler = (event) => {
        document.getElementById("hideIModal").style.maxWidth = "100%";

        document.getElementById("msg").innerHTML = "";
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        setParsedData([]);
        // Filtered Column Names
        setTableRows([]);
        // Filtered Values
        setValues([]);

        console.log(allAdUsers);
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];
                var SpecifyFormat = str => str.replace(" (Yes/No)", "");

                results.data.forEach(elm => delete elm.VouchersReuqested)
                results.data.forEach(elm => delete elm.VouchersApproved)
                results.data.forEach(elm => delete elm.VouchersRemaining)

                var final = results.data.map((d) => {
                    var empData = Object.fromEntries(Object.entries(d).map(([key, value]) => [SpecifyFormat(key), phoneNumber(value)]));
                    var keys = Object.keys(d).map(value => { return value.replace(" (Yes/No)", "") })
                    var values = Object.values(d).map(value => { phoneVal = value; return phoneNumber(value) })

                    rowsArray.push(keys);
                    valuesArray.push(values);
                    return empData;
                });

                // Parsed Data Response in array format
                setParsedData(final);
                // Filtered Column Names
                setTableRows(rowsArray[0]);
                // Filtered Values
                setValues(valuesArray);
            },
        });
    };

    const phoneNumber = event => {
        if (/^[0-9]+$/.test(event) && event.length >= 10) {
            var num = event.replace(/\D/g, '');
            var phoneValue = '(' + num.substring(0, 3) + ')' + num.substring(3, 6) + '-' + num.substring(6, 10);
            return phoneValue;
        } else {
            setIsValidPhone(false);
            return event;
        }
    }

    const loadData = async event => {
        console.log(props);
        const fileUpload = document.getElementById("fileUpload");
        const tableHeader = ['FirstName', 'LastName', 'Email', 'IsAdmin', 'TotalVouchers', 'TxtMessages', 'PhoneNumber', 'Active'];
        var status = true;
        var column1, column2, column3, column4 = "";
        if (fileUpload.value != null && fileUpload.value != "") {
            msalAuth.acquireTokenSilent({
                ...loginRequest,
                account: msalAuth.getAllAccounts()[0]
            }).then(async (response) => {
                await  trackPromise(Promise.all(parsedData.map(async (el,index) => {
                    document.getElementById("tr" + index).classList.remove("tableRowError") ;
                    console.log(el);
                    if (el.FirstName == "" ||
                        el.TotalVouchers == "" ||
                        /*el.TxtMessages == "" ||*/
                        /*el.Carrier == "" ||*/
                        el.Active == "") {
                        document.getElementById("tr" + index).classList.add("tableRowError");
                        column1 = "EmptyFields";
                        status = false;
                    }
                    else if (!validatePhone(el)) {
                        document.getElementById("tr" + index).classList.add("tableRowError");
                        column2 = "PhoneNumber";
                        status = false;
                    }
                    else if (el.Email == "" || el.Email == undefined || !validator.isEmail(el.Email)) {
                        document.getElementById("tr" + index).classList.add("tableRowError");
                        column3 = "Email";
                        status = false;
                    }
                    else if(parseInt(el.TotalVouchers) < 0 || parseInt(el.TotalVouchers) > 32767){
                       document.getElementById("tr" + index).classList.add("tableRowError");
                        column4 = "InvalidTotalVoucher";
                        status = false;
                    }
                    
                   
                    // Acquire token silent success
                    if(!props.allEmployeesEmails.includes(el.Email.trim().toLowerCase())) //check it only if emails not in database.
                    { 
                        await checkEmail(response, el.Email.trim()).then((response) => {
                            if (response == false) {
                                status = false;
                                document.getElementById("tr" + index).classList.add("tableRowError");
                                column3 = "EmailNotFound";
                            }
                        }).catch((error) => {
                            console.log(error);
                            status = false;
                            document.getElementById("tr" + index).classList.add("tableRowError");
                            column3 = "EmailNotFound";
                        });
                     }

                })));

                if (tableHeader.every(t => tableRows.includes(t)) && status != false) {
                    var data = JSON.stringify(parsedData)
                    msalAuth.acquireTokenSilent({
                        ...APIAuth,
                        account: msalAuth.getAllAccounts()[0]
                    }).then((response) => {
                        trackPromise(
                            fetch('Employee/ImportEmployees', prepareHeader(response, "POST", data))
                                .then((response) => response.json())
                                .then(data => {
                                    if (data.statusCode == 200) {
                                        props.hideIModal();
                                        global.toastMeForSuccess("Employees imported successfully.")
                                        props.FetchEmployees();
                                        props.GetAllEmployees();
                                    } else {
                                        global.toastMeForError("Something went wrong.");
                                    }
                                }));
                    });

                }
                else if (tableHeader.every(t => !tableRows.includes(t))) {
                    global.toastMeForError(tableHeader.filter(t => !tableRows.includes(t)) + " column is missing. Please check the CSV file.")
                    return false;
                }
                else if (status == false) {

                    document.getElementById("msg").innerHTML =
                        column1 == "EmptyFields" ? "All the fields are required." : column2 == "PhoneNumber" ? "Valid phone number is required if text messages are on." : column3 == "Email" ? "Please enter valid email." : column3 == "EmailNotFound" ? "Email not found in AD. Please check the output below" : column4 == "InvalidTotalVoucher" ? "Invalid total voucher count." : "";
                    return false;
                }
               
            })



        } else {
            global.toastMeForError("Please select a file.")
            return false;
        }

    }


   function validatePhone(el) {
        var isvalid = true;
        if (el.TxtMessages.toLowerCase() == "yes") {
            if (el.PhoneNumber == "" || el.PhoneNumber == undefined || el.PhoneNumber.length != 13) {
                isvalid = false;
            }
        } else if (el.TxtMessages.toLowerCase() == "no" && el.PhoneNumber != "" && el.PhoneNumber.length != 13) {
                isvalid = false;
        }
        return isvalid;
    }




    return (
        <div>
            <CsvDownload style={{ float: "right", padding: "5px" }} data={jsonArrayTemplate}>Download Template</CsvDownload>
            <div className="row">
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"><h5>Import Employees</h5></div>
                <span id="msg" style={{
                    width: "85%",
                    textAlign: "center",
                    color:"red"
                }}></span>
            </div>
            {/* File Uploader */}
            <input
                id="fileUpload"
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{ display: "block", margin: "35px auto", float: "left" }}
            />
            <div className="row  center mt-4" style={{ height: "55px" }}>
                <div className="col-md-3"></div>
                <div className="col-md-3"><PrimaryButton positive onClick={loadData} className="wld-primary-button">Import</PrimaryButton></div>
                <div className="col-md-3"><PrimaryButton className="wld-secondary-button" onClick={props.hideIModal}>Cancel</PrimaryButton></div>
                <div className="col-md-3"> </div>
            </div>
            {/* Table */}
            <table id="importedtable">
                <thead>
                    <tr>
                        {tableRows.map((rows, index) => {
                           
                            return <th style={{ display: rows == "IsAdmin" && props.roleID != 1 ? 'none' : '' }} key={index}>{rows}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        values.map((value, index) => {
                            return (
                                <tr key={index} id={"tr" + index}>
                                    {value.map((val, i) => {
                                        return <td style={{ display: tableRows.indexOf("IsAdmin") == i && props.roleID != 1 ? 'none' : '' }} key={i}>{val}</td>;
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}


export async function checkEmail(token, email) {
    let isValid = false;
    if (email != "" && validator.isEmail(email)) {
        await new Promise(resolve => {
            getUserByID(token, email).then(data => {
                if (data != null) {
                    isValid = true
                }
                resolve(isValid);
            }).catch((error) => {
                resolve(false);
                isValid = false;
            });
        });
    }

    return isValid;
}

export default ImportEmployees;