import React, { Component, useState } from 'react';
import { PrimaryButton, IconButton } from '@fluentui/react';
import { Modal, Button } from "react-bootstrap";
import { VoucherRequestModal } from './VoucherRequestModal';
import { EditGames } from './EditGames';
import ImportGames from './ImportGames';
import { VoucherRequestHistory } from './VoucherRequestHistory';
import moment from 'moment-timezone';
import { CSVLink } from "react-csv";
import './Toaster';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider";
import { trackPromise } from 'react-promise-tracker';
import { initializeIcons } from '@fluentui/font-icons-mdl2';


export const statusColors = [
    { value: 'Good', Color: 'text-success' },
    { value: 'Fair', Color: 'text-warning' },
    { value: 'Poor', Color: 'text-orange'},
    { value: 'Not Available', Color: 'text-danger' },
    { value: 'At Limit', Color: 'text-danger' },
];



export class Games extends Component {
    static displayName = Games.name;

    constructor(props) {
        super(props);

        this.state = {
            isGModalOpen: false,
            isVModalOpen: false,
            isIModalOpen: false,
            isVGModalOpen: false,
            gamesData: [],
            allGamesData: [],
            voucherData: [],
            voucherDataHeader: [],
            employeeID: this.props.auth.UserID,
            gameID: 0,
            status: "",
            selectedGame: 0,
            sortType: 'ascending',
            key: null,
            sortIcon: 'SortUp',
            RoleID: this.props.auth.RoleID,
            gameHeader: "",
            showPastGames: 0,
            selectedGameTime: null
        };
        this.fetchGames = this.fetchGames.bind(this);
        this.fetchVoucherRequestPergame = this.fetchVoucherRequestPergame.bind(this);


        this.showGModal = this.showGModal.bind(this);
        this.hideGModal = this.hideGModal.bind(this);

        this.showVModal = this.showVModal.bind(this);
        this.hideVModal = this.hideVModal.bind(this);

        this.showVGModal = this.showVGModal.bind(this);
        this.hideVGModal = this.hideVGModal.bind(this);

        this.showIModal = this.showIModal.bind(this);
        this.hideIModal = this.hideIModal.bind(this);
        this.toggelPastGames = this.toggelPastGames.bind(this);
        initializeIcons();
        console.log("gamesProps" + JSON.stringify(this.props));
    }

    componentDidMount() {
        this.fetchGames();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps);
        if (prevProps.auth !== this.props.auth) {
            this.fetchGames();
        }
    }

    //PS:SC To fetch games.
    fetchGames() {
        console.log("gamestate:" + JSON.stringify(this.state));
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
                fetch('Game/fetchGames?employeeID=' + this.state.employeeID + '&showPastGames=' + this.state.showPastGames, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(data => {
                        var result = JSON.parse(data)
                        result.forEach(elm => delete elm.Id)
                        result.forEach(elm => delete elm.RemainingVoucherPerGame)
                        result.forEach(elm => delete elm.RemainingVoucherPerGamePerEmp)
                        result.forEach(elm => delete elm.RemainingVoucherPerEmp)
                        result.forEach(elm => delete elm.SeasonId)
                        result.forEach(elm => delete elm.Approved)
                        result.forEach(elm => delete elm.Pending)
                        result.forEach(elm => delete elm.Declined)
                        result.forEach(elm => delete elm.EditGameTime)
                        result.forEach(elm => delete elm.EditRequestDeadline)
                        this.setState({ gamesData: JSON.parse(data) });
                        this.setState({ allGamesData: result });
                    }));
        });
    }

    fetchVoucherRequestPergame() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
                fetch('Game/FetchVouchersRequestPerGames?status=' + this.state.status + '&gameID=' + this.state.gameID, prepareHeader(response, "GET"))
                    .then((response) => response.json())
                    .then(data => {
                        var result = JSON.parse(data);
                        this.setState({ voucherData: result, voucherDataHeader: result[0] });
                        console.log(this.state.voucherData);
                    }));
        });
    }

    //For Edit Games Voucher
    showGModal(id) {
        this.setState({ isGModalOpen: true });
        this.state.gameID = id;
    }
    hideGModal() {
        this.setState({ isGModalOpen: false });
    }

    //For Voucher Request Per Games
    showVGModal(id, status, GameTitle, GameTime) {
        this.setState({ isVGModalOpen: true });
        this.state.gameID = id;
        this.state.status = status;
        this.state.gameHeader = GameTitle + " : " + moment(GameTime).format("MM/DD/YYYY hh:mm A");
        this.state.selectedGameTime = GameTime;
        this.fetchVoucherRequestPergame();
    }
    hideVGModal() {
        this.setState({ isVGModalOpen: false });
    }

    //For Games Import
    showIModal() {
        this.setState({ isIModalOpen: true });
    }
    hideIModal() {
        this.setState({ isIModalOpen: false });
    }

    //For RequestVoucher Modal
    showVModal(id) {
        this.setState({ isVModalOpen: true });
        this.state.gameID = id;
    }
    hideVModal() {
        this.setState({ isVModalOpen: false });
    }

    toggelPastGames() {
        var currentState = 0;
        if (this.state.showPastGames == 0) {
            currentState = 1
        }
        this.setState({
            showPastGames: currentState
        })

        this.fetchGames();
    }

    requestSort = (key) => {
        this.setState({ key: key });
        if (this.state.sortType === null || this.state.sortType === 'descending')
            this.setState({ sortType: 'ascending', sortIcon: 'SortUp' });
        if (this.state.sortType === 'ascending') {
            this.setState({ sortType: 'descending', sortIcon: 'SortDown' });
        }
    };

    getGameVouchers(gameId) {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
            fetch('Game/getVouchersList?gameID=' + gameId, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    if (data.length > 0) {
                        const toTitleCase = (phrase) => {

                            return phrase
                                .split(',')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(',');
                        };

                        var date = new Date(data[0].game_Time);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var dt = date.getDate();
                        var hr = date.getHours();
                        var min = date.getMinutes();
                        if (dt < 10) {
                            dt = '0' + dt;
                        }
                        if (month < 10) {
                            month = '0' + month;
                        }
                        var gameTime = month.toString() + dt.toString() + year.toString() + "_" + hr + "_" + min;
                        const header = toTitleCase(Object.keys((data[0])).join(',')).replaceAll("_", " ") + '\n';
                        const csv = data.map((row) => Object.values(row).join(',')).join('\n');
                        var csvData = header + csv;
                        var fileName = data[0].opponent + "_" + gameTime
                        const blob = new Blob([csvData], { type: 'text/csv' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.setAttribute('href', url);
                        link.setAttribute('download', fileName + '.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        global.toastMeForWarning("Vouchers not requested")
                    }
                    }));
        });
    }



    render() {
        const date = moment(new Date()).tz('America/Chicago').format('YYYY/MM/DD hh:mm:ss a');

        this.state.gamesData.sort((a, b) => {
            var aSort = this.state.key === "GameTime" ? moment(a[this.state.key]) : String(a[this.state.key]).toLowerCase();
            var bSort = this.state.key === "GameTime" ? moment(b[this.state.key]) : String(b[this.state.key]).toLowerCase()
            if (aSort < bSort) {
                return this.state.sortType === 'ascending' ? -1 : 1;
            }
            if (aSort > bSort) {
                return this.state.sortType === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return (
            <div className="ContentBox ms-Grid ms-depth-4" dir="ltr">
                {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                    <PrimaryButton className="wld-primary-button float-start custombtn" onClick={() => this.showGModal(0)}>Add Game</PrimaryButton> : ""
                }
                {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                    <PrimaryButton className="wld-primary-button custombtn" style={{ float: "right", fontWeight: "600" }}><CSVLink
                        filename={"Games_" + moment(new Date()).format("YYYYMMDDhhmmss") + ".csv"}
                        data={this.state.allGamesData}
                    >
                        Export Games
                    </CSVLink> </PrimaryButton> : ""
                }
                {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                    <PrimaryButton className="wld-primary-button custombtn" style={{ float: "right" }} onClick={this.showIModal}>Import Games</PrimaryButton> : ""
                }
                <div className="fCard Card tableborder">
                    <table className="mld-table table" style={this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ? { fontSize: "13px" } : { fontSize: "inherit" }}>
                        <thead>
                            <tr>
                                <th className="left" scope="col" onClick={() => this.requestSort('GameTitle')}>
                                    Opponent
                                    {this.state.key == "GameTitle" ?
                                        <React.Fragment>
                                            <IconButton
                                                iconProps={{ iconName: this.state.sortIcon }}
                                                styles={{
                                                    icon: {
                                                        color: '#DFC9A2', fontSize: 11
                                                    },
                                                    root: {
                                                        height: 0,
                                                        selectors: {
                                                            ':hover .ms-Button-icon': {
                                                                color: '#DFC9A2'
                                                            },
                                                            ':active .ms-Button-icon': {
                                                                color: '#DFC9A2'
                                                            }
                                                        },
                                                        padding: 0
                                                    }
                                                }} />

                                        </React.Fragment>
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }

                                </th>
                                <th scope="col" onClick={() => this.requestSort('GameTime')}>
                                    Game Time
                                    {this.state.key == "GameTime" ?
                                        <IconButton
                                            iconProps={{ iconName: this.state.sortIcon }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    }
                                                }
                                            }} />
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }
                                </th>
                                <th scope="col" onClick={() => this.requestSort('Status')}>
                                    Status
                                    {this.state.key == "Status" ?
                                        <IconButton
                                            iconProps={{ iconName: this.state.sortIcon }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    }
                                                }
                                            }} />
                                        : <IconButton
                                            iconProps={{ iconName: "ChevronDown" }}
                                            styles={{
                                                icon: {
                                                    color: '#DFC9A2', fontSize: 11
                                                },
                                                root: {
                                                    height: 0,
                                                    selectors: {
                                                        ':hover .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        },
                                                        ':active .ms-Button-icon': {
                                                            color: '#DFC9A2'
                                                        }
                                                    },
                                                    padding: 0
                                                }
                                            }} />
                                    }
                                </th>
                                <th scope="col">Request Deadline</th>
                                <th scope="col">Max Voucher/Game</th>
                                {/*   <th scope="col">Total Vouchers</th>*/}
                                <th scope="col" className="left">Season</th>
                                {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                                    <React.Fragment>
                                        <th scope="col" className="left">Approved</th>
                                        <th scope="col" className="left">Pending</th>
                                        <th scope="col" className="left">Declined</th>
                                    </React.Fragment>
                                    : ""}
                                <th scope="col"> </th>
                                <th scope="col">
                                    <input type="checkbox" id="permissions" title="Show Passed Games" onClick={this.toggelPastGames} className="form-check-input mld-checK float-end" />
                                    <label >Past Games</label>
                                </th>
                                {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                                    <th>Export</th>
                                : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.gamesData.map((item, i) => {
                                var requestDisable = false;
                                var classDisable = '';
                                console.log(item);
                                if (this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) {
                                    if (item.RemainingVoucherPerGamePerEmp == true ||
                                        item.RemainingVoucherPerGame == true ||
                                        item.RemainingVoucherPerEmp == true ||
                                        Date.parse(moment(new Date(item.GameTime)).format("YYYY/MM/DD hh:mm:ss a")) < Date.parse(date)) {
                                        requestDisable = true
                                        classDisable = 'button-Grayed'
                                    }
                                } else {
                                    if (item.RemainingVoucherPerGamePerEmp == true ||
                                        item.RemainingVoucherPerGame == true ||
                                        item.RemainingVoucherPerEmp == true ||
                                        Date.parse(moment(new Date(item.RequestDeadline)).format("YYYY/MM/DD hh:mm:ss a")) < Date.parse(date)) {
                                        requestDisable = true
                                        classDisable = 'button-Grayed'
                                    }
                                }

                                return (
                                    <tr data-id={item.Id}>
                                        <td className="left">{item.GameTitle}</td>
                                        <td>{moment(item.GameTime).format("MM/DD/YYYY hh:mm A")}</td>
                                        <td className={statusColors.find(c => c.value === item.Status)?.Color || ''} >{item.Status}</td>
                                        <td>{moment(item.RequestDeadline).format("MM/DD/YYYY hh:mm A")}</td>
                                        <td>{item.MaxVouchers}</td>
                                        {/*  <td>{item.TotalVouchers}</td>*/}
                                        <td className="left">{item.SeasonName}</td>
                                        {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                                            <React.Fragment>
                                                <td className="voucherDtl" style={{ color: "lightgreen" }} onClick={() => this.showVGModal(item.Id, "Approved", item.GameTitle, item.GameTime)}>{item.Approved}</td>
                                                <td className="voucherDtl" style={{ color: "yellow" }} onClick={() => this.showVGModal(item.Id, "Pending", item.GameTitle, item.GameTime)}>{item.Pending}</td>
                                                <td className="voucherDtl" style={{ color: "red" }} onClick={() => this.showVGModal(item.Id, "Declined", item.GameTitle, item.GameTime)}>{item.Declined}</td>
                                            </React.Fragment>
                                            : ""}
                                        <td><PrimaryButton className={"wld-primary-button " + classDisable} onClick={() => this.showVModal(item.Id)} text="Request Voucher" disabled={requestDisable} /> </td>
                                        <td>{this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ? <PrimaryButton className="wld-primary-button" text="Edit Game" onClick={() => this.showGModal(item.Id)} /> : ""}</td>
                                        {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                                        <td >
                                           
                                            <span title="Export" data-id={item.Id} onClick={() => this.getGameVouchers(item.Id)} >
                                                <svg style={{ cursor: "pointer", border: "#dfc9a2 0.5px solid" }} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                                                    <rect width="24" height="24" fill="#1c6c4e" />
                                                    <path d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12" stroke="#dfc9a2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 3L12 15M12 15L16 11M12 15L8 11" stroke="#dfc9a2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            </td>
                                        : ""}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {this.state.isVModalOpen ? (
                    <Modal id="hideVModal" size="lg" show={this.state.isVModalOpen} onHide={this.hideVModal}>
                        <Modal.Body className="ModalContentBox">
                            <VoucherRequestModal gameID={this.state.gameID} employeeID={this.state.employeeID} RoleID={this.state.RoleID} hideVModal={this.hideVModal} fetchGames={this.fetchGames} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.state.gamesData.gameID)
                )}

                {this.state.isGModalOpen ? (
                    console.log(this.state.gamesData.gameID),
                    <Modal id="hideGModal" className="wld-Modal-md" size="lg" show={this.state.isGModalOpen} onHide={this.hideGModal}>
                        <Modal.Body className="ModalContentBox">
                            <EditGames gameID={this.state.gameID} employeeID={this.state.employeeID} hideGModal={this.hideGModal} fetchGames={this.fetchGames} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.state.gamesData.gameID)
                )}

                {this.state.isIModalOpen ? (
                    <Modal id="hideIModal" className="wld-Modal-md" size="lg" show={this.state.isIModalOpen} onHide={this.hideIModal}>
                        <Modal.Body className="ModalContentBox">
                            <ImportGames gameID={this.state.gameID} employeeID={this.state.employeeID} hideIModal={this.hideIModal} fetchGames={this.fetchGames} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.state.gamesData.gameID)
                )}

                {this.state.isVGModalOpen ? (
                    <Modal id="hideVGModal" className="wld-Modal-md" size="lg" show={this.state.isVGModalOpen} onHide={this.hideVGModal} style={{ width: "125% !important" }}>
                        <Modal.Body className="ModalContentBox">
                            <VoucherRequestHistory gameID={this.state.gameID} hideVGModal={this.hideVGModal} fetchGames={this.fetchGames} gameHeader={this.state.gameHeader} FetchVouchers={this.fetchVoucherRequestPergame} RoleID={this.state.RoleID} status={this.state.status} historyData={this.state.voucherData} Page={"game"} selectedGameTime={this.state.selectedGameTime} />
                        </Modal.Body>
                    </Modal>
                ) : (
                    console.log(this.state.gamesData.gameID)
                )}

            </div>
        );
    }
}