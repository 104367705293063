import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { GetEmployeeAuthDetails } from './AuthProvider';
import { Redirect } from "react-router-dom";
import { RequestAccess } from './RequestAccess';
import { PrimaryButton } from '@fluentui/react';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider"
import { APIAuth, loginRequest } from '../msal/MsalConfig';
import { trackPromise } from 'react-promise-tracker';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            status: false,
            RoleID: this.props.RoleID,
            IsActive: false,
            loading:true
        };
        console.log(this.props);
    }

    componentDidMount() {
        this.getMaintenanceDetails();
        this.setState({
            loading: true
        })
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers
            };
            trackPromise(
            GetEmployeeAuthDetails(options).then((response) => response.json())
                .then((data) => {
                    var result = JSON.parse(data);
                    if (result != null) {
                    this.setState({
                        RoleID: result.RoleId,
                        IsActive: result.IsActive
                    })
                    }
                    this.setState({
                        loading: false
                    })
                }));
        }).catch((error) => {
            
            // If .catch is called, this means your app was returning from a redirect operation
            // and an error occurred.
            msalAuth
            .acquireTokenRedirect({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            })
        });
    }

    getMaintenanceDetails() {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Maintenance/getMaintenanceDetails', prepareHeader(response, "GET"))
            .then((response) => response.json())
            .then(data => {
                var result = JSON.parse(data);
                this.setState({ status: result })
            });
        });
    }

    render() {

        return (
            <div>
                {(this.state.status == true || this.props.RoleID == 1 || this.props.RoleID == 2) && this.state.IsActive == true && !this.state.loading ?
                    <React.Fragment>
                        <NavMenu {...this.props} />
                        <Container>
                            {this.props.children}
                        </Container>
                    </React.Fragment >
                    : this.state.IsActive != true && this.props.RoleID != null ?
                        <React.Fragment>
                            <h3>Your account is currenty deactivated. Please contact admin to activate</h3>
                            <PrimaryButton className="wld-primary-button" onClick={this.props.onSignOut} > Sign Out </PrimaryButton>
                        </React.Fragment >
                        : this.props.RoleID == null && !this.state.loading  ?
                            <React.Fragment>
                                <NavMenu {...this.props} />
                                <Container>
                                    <RequestAccess {...this.props} />
                                </Container>
                            </React.Fragment >
                            : this.state.loading ? <React.Fragment>
                                <NavMenu {...this.props} />
                                <Container>
                                   Loading...
                                </Container>
                            </React.Fragment >
                            : "Application is under maintenance."

                }
            </div>
        );
    }
}
