import React, { Component } from 'react';
import { Route , Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import './responsive.css'
import { Games } from './components/Games';
import { Employee, EmployeeUpdateModel } from './components/Employee';
import { Schedule } from './components/Schedule';
import { Maintenance } from './components/maintanance';
import { Season } from './components/Season';
import { RequestAccess } from './components/RequestAccess';
import { PageNotFound } from './components/PageNotFound';

//MSAL components
import { withMsal, MsalProvider, useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./msal/MsalConfig";
import { withAuth } from "./components/AuthProvider";
import { APIAuth } from './msal/MsalConfig';
import { msalAuth, prepareHeader } from "./components/AuthProvider";






function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: this.props.msalContext.accounts.length > 0,
            user: this.props.msalContext.instance.getAllAccounts()[0],
            RoleID: null,
            isRegistered: false,
            userID: 0,
            requestID: 0,
            empData: {
                ID: 0,
                UserName: '',
                FirstName: '',
                LastName: '',
                Email: '',
                SecurityStamp: '',
                TotalVouchers: '',
                TxtMessages: false,
                PhoneNumber: '',
                PhoneNumberConfirmed: false,
                //Carrier: '',
                Status: false,
                ExternalIdentifier: ''
            },
            isActive:false
        };

        



        this.CheckAuth = this.CheckAuth.bind(this);
    }



    componentDidMount() {
       // this.getUserDetails();
        const msalInstance = this.props.msalContext.instance;
        const account = msalInstance.getAllAccounts()[0];

        // handling the auth after login redirect
        msalInstance.handleRedirectPromise()
            .then(result => {
                const maslResult = result;
                if (maslResult != null) {
                    this.CheckAuth(result.account);

                }
                else {
                    msalInstance.loginRedirect();
                }
            })
            .catch(error => {

                console.log(error);
                // If .catch is called, this means your app was returning from a redirect operation
                // and an error occurred.
            });

            //Get Role and registration details 
        if (account != null) {
            this.CheckAuth(account);
        }
        else {
            msalInstance.loginRedirect();
        }
        console.log(this.state);
        
    }

    CheckAuth(account) {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Account/CheckAuth?email=' + account.username, prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    var result = JSON.parse(data);
                    if (result != null) {
                        this.setState({
                            RoleID: result.RoleId,
                            userID: result.ID,
                            isRegistered: true,
                            isActive: result.isActive
                        })

                        if (result.ExternalIdentifier == null || result.ExternalIdentifier == "") {
                            var empModel = EmployeeUpdateModel;
                            empModel.Email = result.Email;
                            empModel.ExternalIdentifier = result.ExternalIdentifier;

                            let data = JSON.stringify(EmployeeUpdateModel);
                            fetch('Employee/UpdateEmployee', prepareHeader(response, "POST", data))
                                .then((response) => response.json())
                                .then(data => {
                                    var result = data;
                                    console.log(result);
                                });

                        }
                    }
                    return
                })
        })
    }


    onSignOut() {
        const msalInstance = this.props.msalContext.instance;
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
    }

    render() {
        const Authdetails = this.state;
        const RoleID = this.state.RoleID;
 
        return (
            <div className='App'>
                <wc-toast position="top-center">
                    <slot></slot>
                </wc-toast>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    errorComponent={ErrorComponent}
                    loadingComponent={LoadingComponent}
                    authenticationRequest={loginRequest}
                >
                    <Layout onSignOut={() => this.onSignOut()} RoleID={this.state.RoleID}  {...this.props}>
                        <Switch>
                            <Route exact path='/' component={withAuth(Home)} />
                            <Route path='/counter' component={Counter} />
                            <Route path='/games' component={withAuth(Games)} />
                            <Route path='/seasons' component={withAuth(Season)} />
                            <Route path='/employees' component={withAuth(Employee)} />
                            <Route path='/maintenance-schedule' component={withAuth(Maintenance)} />
                            <Route path='/request-access' render={(props) => <RequestAccess {...this.props} />} />
                            <Route path='/ConfirmRequest/:userID/:requestID/:isApproved' component={withAuth(Employee)} />
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                            <Route path='*' exact={true} component={PageNotFound} />
                        </Switch>
                    </Layout>
                </MsalAuthenticationTemplate>
            </div>
        );
    }
}


function Authorized() {
    return <p>Authentication in progress...</p>;
}

export default App = withMsal(App);