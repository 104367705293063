
import { PublicClientApplication } from "@azure/msal-browser";
import { APIAuth , msalConfig } from '../msal/MsalConfig';
var graph = require('@microsoft/microsoft-graph-client');

function getGraphClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });

    return client;
}

export async function getUserDetails(accessToken) {
    const client = getGraphClient(accessToken);
    return await client.api('/me/memberOf').get();
}

//SC : get the user by id from AD
export async function getUserByID(accessToken, ID) {
        const client = getGraphClient(accessToken);
        return client.api('/users/' + ID).get();
}






