import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader , Roles } from "./AuthProvider";
import { trackPromise } from 'react-promise-tracker';
import { VoucherRequestModal } from './VoucherRequestModal';
import { Modal, Button } from "react-bootstrap";
import moment from 'moment-timezone';



export class VoucherRequestHistory extends Component {
    static displayName = VoucherRequestHistory.name;


    constructor(props) {
        super(props);
        this.state = {
            VoucherHistoryData: this.props.historyData,
            IsAuthorize: false,
            RoleID: props.RoleID,
            Page: props.Page,
            gameStatus: props.status,
            selectedVouchers: [],
            voucherUpdateFailuers: [],
            isVRModalOpen: false,
            selectedVoucher:0
        };
        this.respondToRequest = this.respondToRequest.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.UpdateVouchers = this.UpdateVouchers.bind(this);
        this.openVRModal = this.openVRModal.bind(this);
        this.closeVRModal = this.closeVRModal.bind(this);
        this.fetchVouchers = this.fetchVouchers.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps);
        if (prevProps.historyData !== this.props.historyData) {
            this.setState({
                VoucherHistoryData: this.props.historyData
            });
        }
    }

    respondToRequest(status, voucherID) {
        trackPromise(
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                trackPromise(
                    fetch('Home/UpdateVoucher?status=' + status + '&voucherID= ' + voucherID + '', prepareHeader(response, "GET"))
                        .then((response) => response.json())
                        .then(data => {
                            // var result = JSON.parse(data);
                            console.log(data);
                            if (data) {
                                global.toastMeForSuccess('Voucher request ' + status.toLowerCase() + ' Successfully')
                                this.props.getVoucherRequested();
                            }
                        }));
            }));
    }

    UpdateVouchers(status) {
        let selectedVouchers = []
        this.state.VoucherHistoryData.map((item) => {
            if (item.IsChecked) {
                selectedVouchers.push({ status: status, voucherID: item.VoucherID })
            }
        })
        var data = JSON.stringify(selectedVouchers);
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            trackPromise(
                fetch('Game/UpdateVouchers', prepareHeader(response, "POST", data))
                    .then((response) => response.json())
                    .then(data => {
                        if (Array.isArray(data)) {
                            this.setState({
                                voucherUpdateFailuers: data
                            })
                            global.toastMeForWarning("Vouchers updated with some exceptions.Please see below summary for more information.");
                        } else {
                            global.toastMeForSuccess("Vouchers updated successfully.");
                        }
                        this.props.FetchVouchers();
                        this.props.fetchGames();
                    }));
        });
    }

    handleChange = (e) => {
        const { value, checked } = e.target;

        if (e.target.name == "allVouchers") {
            let tempSelected = this.state.VoucherHistoryData.map((item) => {
                return { ...item, IsChecked: checked }
            });
            this.setState({
                VoucherHistoryData: tempSelected
            })
        }
        else {
            let tempSelected = this.state.VoucherHistoryData.map((item) =>
            item.VoucherID == value ? {...item,IsChecked: checked} : item
            );
            this.setState({
                VoucherHistoryData: tempSelected
            })
        }

        

        console.log(this.state.VoucherHistoryData);
    }

    //PS:SC Open voucher request modal for pending vouchers editing.
    openVRModal(id) {
        if (id != false) {
            this.setState({
                isVRModalOpen: true,
                selectedVoucher:id   
            })
        }
    }

    closeVRModal() {
        this.setState({
            isVRModalOpen: false,
            selectedVoucher: 0
        })
    }

    fetchVouchers() {
        this.props.FetchVouchers();
    }


    render() {
        return (
            <div>
                {this.state.Page == "game" ?
                    <div className="row mld-row mt-4">
                    <div className="col-md-6 h6 mld-text-gold"> {this.props.gameHeader} </div>
                        {(this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) && (this.state.gameStatus == "Pending" || this.state.gameStatus == "Declined") ?
                        <div className="col-md-6">
                            <PrimaryButton style={this.state.gameStatus == "Declined" ? { display: 'none' } : { display: 'flex' } } disabled={!this.state.VoucherHistoryData.some(el => el.IsChecked == true)} onClick={this.props.hideModal} onClick={() => this.UpdateVouchers("Declined")} className="wld-primary-button gameRemoveButton  float-end">Decline</PrimaryButton>
                            <PrimaryButton disabled={!this.state.VoucherHistoryData.some(el => el.IsChecked == true)} positive onClick={this.handleEmpSubmit} onClick={() => this.UpdateVouchers("Approved")} className="wld-primary-button float-end">Approve</PrimaryButton>
                        </div>
                        : ""
                    }
                    </div> 
                    : ""}
                <div { ...this.state.Page == "game" ? { className : "fCard Card tableborder" } : "" }>
                <table className="table mld-table">
                <thead>
                    <tr>
                                {(this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) && this.state.Page == "game" && (this.state.gameStatus == "Pending" || this.state.gameStatus == "Declined") && Date.parse(this.props.selectedGameTime) > Date.now() ?
                                <th><input type="checkbox" className="form-check-input mld-checK" onChange={this.handleChange} name="allVouchers" title="Select All" /></th> : ""
                        }
                        <th scope="col">Date requested</th>
                        {this.state.Page != "game" ?
                            <React.Fragment>
                                <th className="left" scope="col">Opponent</th>
                                <th scope="col">Game Time</th>
                            </React.Fragment>: ""
                        }
                        {this.state.RoleID.some(s=> Roles.generalAdmins.includes(s)) ?
                            <React.Fragment>
                                <th className="left" scope="col">First Name</th>
                                <th className="left" scope="col">Last Name</th>
                            </React.Fragment>
                            : ""
                        }
                        <th scope="col">Vouchers</th>
                        <th scope="col">Accessible</th>
                        <th scope="col">Status</th>
                        {this.state.Page != "game" ?
                            <React.Fragment>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </React.Fragment>
                            : ""
                        }
                    </tr>
                </thead>
                <tbody>
                            {this.state.VoucherHistoryData.map((item, i) => {
                                const currentDate = moment(new Date()).tz('America/Chicago').format('YYYY/MM/DD hh:mm:ss a');
                                var editDisabled = false;
                                var classDisable = ''

                                if (this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) {
                                    if (Date.parse(moment(new Date(item.GameTime)).format("YYYY/MM/DD hh:mm:ss a")) < Date.parse(currentDate) || item.Status != "Pending") {
                                        editDisabled = true;
                                        classDisable = 'link-grayed';
                                    }
                                }
                                else {
                                    if (Date.parse(moment(new Date(item.RequestDeadline)).format("YYYY/MM/DD hh:mm:ss a")) < Date.parse(currentDate) || item.Status != "Pending") {
                                        editDisabled = true;
                                        classDisable = 'link-grayed';
                                    }
                                }


                                return (
                                <tr>
                                    {(this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) && this.state.Page == "game" && (this.state.gameStatus == "Pending" || this.state.gameStatus == "Declined") && Date.parse(item.GameTime) > Date.now() ?
                                        <td><input type="checkbox" onChange={this.handleChange} checked={item?.IsChecked || false} className="form-check-input mld-checK" value={item.VoucherID} /></td> : ""
                                    }
                                    <td scope="row">{item.DateRequested}</td>
                                    {this.state.Page != "game" ?
                                        <React.Fragment>
                                            <td className="Active left">{item.Opponent}</td>
                                            <td>{item.GameTime}</td>
                                        </React.Fragment>
                                        : ""
                                    }
                                    {this.state.RoleID.some(s => Roles.generalAdmins.includes(s)) ?
                                        <React.Fragment>
                                            <td className="left">{item.FirstName}</td>
                                            <td className="left">{item.LastName}</td>
                                        </React.Fragment>
                                        : ""
                                    }
                                    <td>{item.Vouchers}</td>
                                    <td>{item.AccessibleSeating}</td>
                                        <td><a href="javascript:void(0)" disabled={editDisabled} onClick={() => { editDisabled ? this.openVRModal(false) : this.openVRModal(item.VoucherID) }} className={item.Status == "Approved" ? "link_approved" : item.Status == "Declined" ? "link-decline" : "mld-text-gold " + classDisable }>{item.Status}</a></td>
                                    {item.Status === "Pending" && (this.state.RoleID.some(s => Roles.generalAdmins.includes(s))) && Date.parse(item.GameTime) > Date.now() ?
                                        <React.Fragment>
                                            <td><a href="javascript:void(0)" onClick={() => this.respondToRequest("Approved", item.VoucherID)} className="mld-text-gold">Approve</a></td>
                                            <td><a href="javascript:void(0)" onClick={() => this.respondToRequest("Declined", item.VoucherID)} className="text-danger">Decline</a></td>
                                        </React.Fragment>
                                        : <React.Fragment><td></td><td></td></React.Fragment>
                                    }
                                </tr>
                            )})}
                        {this.state.VoucherHistoryData.length == 0 ?
                            <React.Fragment>
                                <tr><td colSpan={ 7 }>Voucher requests not found.</td></tr>
                            </React.Fragment> : ""
                        }
                    </tbody>
                    </table>
                </div>
                {this.state.Page == "game" && this.state.voucherUpdateFailuers.length > 0 ?
                    this.state.voucherUpdateFailuers.map((item) => (
                        <ul style={{color:"red"}}>
                            <li> {item}</li>
                        </ul>
                    ))
                   
                : ""}
                {this.state.Page == "game" ? 
                <div className="row  center mt-4" style={{ height: "55px" }}>
                    <div className="col-md-5"></div>
                    <div className="col-md-2"><PrimaryButton className="wld-secondary-button" onClick={this.props.hideVGModal}>Close</PrimaryButton></div>
                    <div className="col-md-4"></div>
       
                </div>
                    : ""}

               {/* PS:SC Open voucher request modal for pending vouchers. --> */}
                {this.state.isVRModalOpen ? (
                    <Modal id="hideVGModal" className="wld-Modal-md" size="lg" show={this.state.isVRModalOpen} onHide={this.closeVRModal} style={{ width: "125% !important" }}>
                        <Modal.Body className="ModalContentBox">
                            <VoucherRequestModal voucherID={this.state.selectedVoucher} page="home" employeeID={this.props.employeeId} hideVModal={this.closeVRModal} fetchVouchers={() => { this.props.getVoucherRequested() }} RoleID={this.state.RoleID}  />
                        </Modal.Body>
                    </Modal>
                ) : (
                   ""
                )}

            </div>
        );
    }
}
