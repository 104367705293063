import React, { Component } from 'react';
import { msalConfig , APIAuth } from '../msal/MsalConfig';
import { PublicClientApplication } from "@azure/msal-browser";


export const msalAuth = new PublicClientApplication(msalConfig);

export function withAuth(HocComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                UserID: 0,
                RoleID: 0,
            };

          
        }

        async componentDidMount() {
            msalAuth.acquireTokenSilent({
                ...APIAuth,
                account: msalAuth.getAllAccounts()[0]
            }).then((response) => {
                const headers = new Headers();
                const bearer = `Bearer ${response.accessToken}`;

                headers.append("Authorization", bearer);

                const options = {
                    method: "GET",
                    headers: headers
                };
            GetEmployeeAuthDetails(options).then((response) => response.json())
                .then((data) => {
                    var result = JSON.parse(data);
                    this.setState({
                        RoleID: result.RoleId,
                        UserID: result.ID
                    })
                });
            });
        }

        render() {
            if (this.state.UserID !== 0) {
                return <HocComponent auth={this.state}  {...this.props} />;
            }
            else {
                return <div>Loading</div>
            }
        }
    };
}

export async function GetEmployeeAuthDetails(options) {
    let userAccount = msalAuth.getAllAccounts()[0];
    console.log(userAccount);
    return fetch('Account/CheckAuth?email=' + userAccount.username,options);
}

export async function aquireAccessTokenSilent() {
    msalAuth.acquireTokenSilent({
        ...APIAuth,
        account: msalAuth.getAllAccounts()[0]
    }).then((response) => {
        return  response;   
    });
}

//To Prepare headers with auth
export function prepareHeader(response,type,data = null){
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const options = {
        method: type,
        headers: headers,
        body:data
    };

    return options;
}

export const Roles = {      //roles used in the app
    generalAdmins: [1, 2], //this is for general admin functionalities.
    globalAdmin: 1,
    userRoles: [3],
    ITAdmin: 4
}
