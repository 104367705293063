import { useState } from "react";
import Papa from "papaparse";
import "../dataTable.css";
import { PrimaryButton } from '@fluentui/react';
import { forEach } from "react-bootstrap/esm/ElementChildren";
import CsvDownload from "react-json-to-csv";
import validator from 'validator'
import { invalid } from "moment";
import { APIAuth } from '../msal/MsalConfig';
import { msalAuth, prepareHeader, Roles } from "./AuthProvider"
import { trackPromise } from 'react-promise-tracker';
import { dateCheck } from './EditGames';

function ImportGames(props) {
    // State to store parsed data
    const [parsedData, setParsedData] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);

    //State to store the values
    const [values, setValues] = useState([]);

    const [seasonValues, setSeasons] = useState([]);

    const [activeSeason, setActiveSeason] = useState({})

    const jsonArrayTemplate = [
        {
            "GameTitle": "Test",
            "GameTime (Date & Time)": "5/10/2022 9:15",
            "Status (Good/Poor)": "Good",
            "MaxVouchers": 6,
            "RequestDeadline (Date & Time)": "5/10/2022 10:15",
            "SeasonName": "2022-23",
            "TotalVouchers": 0
        }
    ]

    const changeHandler = (event) => {
        Season();
        document.getElementById("hideIModal").style.maxWidth = "70%";
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];
                const date = new Date();
                var SpecifyFormat = str => str.includes("Status") ? str.replace(" (Good/Poor)", "") : str.replace(" (Date & Time)", "");
                // Iterating data to get column name and their values
                var final = results.data.map((d) => {
                    var gameData = Object.fromEntries(Object.entries(d).map(([key, value]) => [SpecifyFormat(key), value]));
                    var keys = Object.keys(d).map(value => { return value.replace(" (Good/Poor)", "") })
                    keys = keys.map(value => { return value.replace(" (Date & Time)", "") })
                    rowsArray.push(keys);
                    valuesArray.push(Object.values(d));
                    return gameData;
                });
                console.log(final);
                // Parsed Data Response in array format
                setParsedData(final);
                // Filtered Column Names
                setTableRows(rowsArray[0]);
                // Filtered Values
                setValues(valuesArray);
            },
        });
    };

    const Season = event => {
        msalAuth.acquireTokenSilent({
            ...APIAuth,
            account: msalAuth.getAllAccounts()[0]
        }).then((response) => {
            fetch('Season/FetchSeason', prepareHeader(response, "GET"))
                .then((response) => response.json())
                .then(data => {
                    var result = JSON.parse(data);
                    console.log(JSON.parse(data));
                    setSeasons(JSON.parse(data));
                    setActiveSeason(result.filter(s => s.IsActive == 1)[0]);
                });
        })
    }

    const loadData = event => {
        console.log(seasonValues);
        var count = 0;
        var status = true;
        var date = new Date();
        var lastDay = new Date(new Date(new Date().setMonth(7)).setDate(0)).getDate();
        var column1, column2, column3, column4 , column5 , column6 , column7 = "";
        const fileUpload = document.getElementById("fileUpload");
        const tableHeader = ['GameTitle', 'GameTime', 'Status', 'MaxVouchers', 'RequestDeadline', 'SeasonName', 'TotalVouchers'];
        parsedData.forEach((el) => {
            document.getElementById("tr" + count).classList.remove("tableRowError");
            const gameTime = new Date(el.GameTime);
            const requestDeadline = new Date(el.RequestDeadline);
            if (gameTime < requestDeadline || isNaN(gameTime) || isNaN(requestDeadline)) {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column1 = "GameTime";
                status = false;
            }
            if (parseInt(el.MaxVouchers) > parseInt(el.TotalVouchers)) {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column2 = "MaxVouchers";
                status = false;
            }
            if (seasonValues.some(item => item.SeasonName.includes(el.SeasonName))) {
                console.log(true);
            } else {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column3 = "Season";
                status = false;
            }
            if (!dateCheck(activeSeason.StartDate, activeSeason.EndDate, el.GameTime)) {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column4 = "GameTime";
                status = false;
            }
            if (Date.parse(el.GameTime) < Date.now()) {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column5 = "GameTime";
                status = false
            }
            if (parseInt(el.MaxVouchers) > 999 || el.MaxVouchers == "") {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column6 = "MaxVouchers";
                status = false;
            }
            if (parseInt(el.TotalVouchers) > 100000 || el.TotalVouchers == "") {
                document.getElementById("tr" + count).classList.add("tableRowError");
                column7 = "TotalVoucher";
                status = false;
            }
            count++;
        })
        if (fileUpload.value != null && fileUpload.value != "") {
            if (tableHeader.every(t => tableRows.includes(t)) && status != false) {
                var data = JSON.stringify(parsedData)
                props.hideIModal();
                msalAuth.acquireTokenSilent({
                    ...APIAuth,
                    account: msalAuth.getAllAccounts()[0]
                }).then((response) => {
                    trackPromise(
                        fetch('Game/ImportGames', prepareHeader(response, "POST", data))
                            .then((response) => response.json())
                            .then(data => {
                                var result = data;
                                console.log(result);
                                global.toastMeForSuccess("Games imported successfully.")
                                props.fetchGames();
                            }));
                });

            } else if (tableHeader.every(t => !tableRows.includes(t))) {
                global.toastMeForError(tableHeader.filter(t => !tableRows.includes(t)) + " column is missing. Please check the CSV file.")
                return false;
            }
            else if (status == false) {
                document.getElementById("msg").innerHTML = column1 == "GameTime" && column2 == "MaxVouchers" ? "Game time cannot be less than request deadline and max voucher can not be greater than total voucher" :
                    column1 == "GameTime" ? "Game time cannot be less than request deadline." : column3 == "Season" ? "Season name does not exist. Please enter a valid season name." : column4 == "GameTime" ? "The game time should fall between the start and end dates of the current season." : column5 == "GameTime" ? "The game time should be greater than current date." :
                        column6 == "MaxVouchers" ? "Invalid max voucher count" : column7 == "TotalVoucher" ? "Invalid total voucher count." : "Max voucher can not be greater than total voucher.";
                return false;
            }
        } else {
            global.toastMeForError("Please select a file.")
            return false;
        }

    }

    return (
        <div>
            <CsvDownload style={{ float: "right", padding: "5px" }} data={jsonArrayTemplate}>Download Template</CsvDownload>
            <div className="row">
                <div style={{ width: "15%" }} className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"><h5>Import Games</h5>
                </div>
                <span id="msg" style={{
                    width: "85%",
                    textAlign: "center",
                    color: "red"
                }}></span>
            </div>
            {/* File Uploader */}
            <input
                id="fileUpload"
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{ display: "block", margin: "35px auto", float: "left" }}
            />
            <div className="row  center mt-4" style={{ height: "55px" }}>
                <div className="col-md-3"></div>
                <div className="col-md-3"><PrimaryButton positive onClick={loadData} className="wld-primary-button">Import</PrimaryButton></div>
                <div className="col-md-3"><PrimaryButton className="wld-secondary-button" onClick={props.hideIModal}>Cancel</PrimaryButton></div>
                <div className="col-md-3"> </div>
            </div>
            {/* Table */}
            <table id="importedtable">
                <thead>
                    <tr>
                        {tableRows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        values.map((value, index) => {
                            return (
                                <tr key={index} id={"tr" + index}>
                                    {value.map((val, i) => {
                                        return <td key={i}>{val}</td>;
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default ImportGames; 
