import React, { Component } from 'react';

export class PageNotFound extends Component {
    static displayName = PageNotFound.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
    }

    render() {
        return (
            <div className="ContentBox ms-Grid ms-depth-4 align-items-center d-flex" dir="ltr">
                <div class="col-md-12">
                    <div class="error-template">
                        <h1>
                            Oops!</h1>
                        <h2>
                            Page Not Found</h2>
                        <div class="error-details">
                            <p>We can't find the page you are looking for.</p>
                        </div>
                        <div class="error-actions">
                            <a href="/" class="btn btn-primary wld-primary-button btn-lg"><span class="glyphicon glyphicon-home"></span>
                                Visit Homepage
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
